/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import s from "./FAQ.module.scss";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary className={s.summery} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function FAQ() {
  const [expanded, setExpanded] = useState<string | false>("faq-acc1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div className={s.FAQ}>
      <Accordion
        expanded={expanded === "faq-acc1"}
        onChange={handleChange("faq-acc1")}
        className={s.accordion}
      >
        <AccordionSummary
          className={s.summery}
          aria-controls="panel1d-content"
          id="faq-acc1"
        >
          <div className={s.summeryContent}>
            <div className={s.summeryTitle}>What is ACE token?</div>
            <div
              className={`${s.iconWrapper} ${
                expanded === "faq-acc1" ? s.open : ""
              }`}
            >
              <span className="icon-arrowDown" />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={s.details}>
          <div className={s.detailsContent}>
            There are two tokens in our project TRC — governance token (issue
            5.000.000.000). IEO in Q1 2023. ACE — token for in-game earnings and
            purchases (unlimited issue). This system is used in many GameFi
            projects to be able to regulate the domestic economy.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "faq-acc2"}
        onChange={handleChange("faq-acc2")}
        className={s.accordion}
      >
        <AccordionSummary
          className={s.summery}
          aria-controls="panel1d-content"
          id="faq-acc2"
        >
          <div className={s.summeryContent}>
            <div className={s.summeryTitle}>
              How can I download the TRACE app and start playing?
            </div>
            <div
              className={`${s.iconWrapper} ${
                expanded === "faq-acc2" ? s.open : ""
              }`}
            >
              <span className="icon-arrowDown" />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={s.details}>
          <div className={s.detailsContent}>
            Only holders of our NFTs can access the app and TRACE Metaverse.
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "faq-acc3"}
        onChange={handleChange("faq-acc3")}
        className={s.accordion}
      >
        <AccordionSummary
          className={s.summery}
          aria-controls="panel1d-content"
          id="faq-acc3"
        >
          <div className={s.summeryContent}>
            <div className={s.summeryTitle}>How can I buy TRACE NFT Car?</div>
            <div
              className={`${s.iconWrapper} ${
                expanded === "faq-acc3" ? s.open : ""
              }`}
            >
              <span className="icon-arrowDown" />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={s.details}>
          <div className={s.detailsContent}>
            On January, there will be Public Sale where you can buy NFT Cars.
            Currently you can get Whitelisted and subscribe to our socials so
            that you will not miss any important news!
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "faq-acc4"}
        onChange={handleChange("faq-acc4")}
        className={s.accordion}
      >
        <AccordionSummary
          className={s.summery}
          aria-controls="panel1d-content"
          id="faq-acc4"
        >
          <div className={s.summeryContent}>
            <div className={s.summeryTitle}>How to get WL?</div>
            <div
              className={`${s.iconWrapper} ${
                expanded === "faq-acc4" ? s.open : ""
              }`}
            >
              <span className="icon-arrowDown" />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={s.details}>
          <div className={s.detailsContent}>
            Read this information in our Discord on #how-to-wl channel{" "}
            <a href="https://discord.gg/trace">https://discord.gg/trace</a>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "faq-acc5"}
        onChange={handleChange("faq-acc5")}
        className={s.accordion}
      >
        <AccordionSummary
          className={s.summery}
          aria-controls="panel1d-content"
          id="faq-acc5"
        >
          <div className={s.summeryContent}>
            <div className={s.summeryTitle}>
              Roadmap, Team, Tokenomics, Whitepaper, Partnerships…
            </div>
            <div
              className={`${s.iconWrapper} ${
                expanded === "faq-acc5" ? s.open : ""
              }`}
            >
              <span className="icon-arrowDown" />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={s.details}>
          <div className={s.detailsContent}>
            Read more information on our website!{" "}
            <a href="https://trace.top">https://trace.top</a>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default FAQ;
