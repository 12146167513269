import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import authorizationBack from "images/authorizationBack.jpg";
import logoImg from "images/logoWhite.svg";

import s from "./Welcome.module.scss";

interface Props {
  children: React.ReactNode;
  formSubmitHandler: (e: React.FormEvent<HTMLFormElement>) => void;
}

function Welcome({ children, formSubmitHandler }: Props) {
  const { t } = useTranslation();

  return (
    <div className={s.welcome}>
      <div className={s.headerContainer}>
        <div className={s.header}>
          <div className={s.logoWrapper}>
            <img src={logoImg} alt="" className={s.logo} />
            <NavLink className="link" to="/" />
          </div>
        </div>
      </div>
      <div className={s.authorizationContainer}>
        <div className={s.authorization}>
          <form className={s.form} onSubmit={formSubmitHandler}>
            <div className={s.title}>{t("authorization.title")}</div>
            {children}
          </form>
        </div>
      </div>
      <div className={s.background}>
        <img src={authorizationBack} alt="" className={s.image} />
      </div>
    </div>
  );
}

export default Welcome;
