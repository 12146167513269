import React, { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Loader from "react-loaders";

import useChangeExtra from "hooks/query/useChangeExtra";
import useGetUser from "hooks/query/useGetUser";
import getFirebaseIdToken from "utils/getFirebaseIdToken";

import MajorSelect from "components/Select/MajorSelect";
import Welcome from "components/Welcome";
import Button from "components/Button";
import ButtonLink from "components/ButtonLink";

import s from "./ExtraUserInputsPage.module.scss";

const endpoint = process.env.REACT_APP_API_ENDPOINT || "";

const selectOptions = [
  {
    title: "Community Member",
    value: "Community Member",
  },
  {
    title: "Influencer",
    value: "Influencer",
  },
  {
    title: "Advertiser",
    value: "Advertiser",
  },
  {
    title: "Holder",
    value: "Holder",
  },
];

function ExtraUserInputsPage() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutateAsync: changeExtra, isLoading } = useChangeExtra();

  const [token, setToken] = useState<string | undefined>("");
  const [chosenRole, setChosenRole] = useState<string>("");
  const [writtenSocials, setWrittenSocials] = useState<string>("");
  const [userRevalidateInterval, setUserRevalidateInterval] =
    useState<number>(0);

  const { data: user } = useGetUser(userRevalidateInterval);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setChosenRole(value);
  };

  const handleSocialsWrite = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setWrittenSocials(e.currentTarget.value);
  };

  const formSubmitHandler = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      await changeExtra({ private_info: writtenSocials, role: chosenRole });
    },
    [writtenSocials, chosenRole],
  );

  const connectTelegramHandler = () => {
    setUserRevalidateInterval(3000);
  };

  useEffect(() => {
    return () => {
      setUserRevalidateInterval(0);
    };
  }, []);

  useEffect(() => {
    const getToken = async () => {
      const value = await getFirebaseIdToken();
      setToken(value);
    };
    getToken();
  }, []);

  useEffect(() => {
    if (user?.is_verified_holder) {
      changeExtra({ role: "Holder" });
    }
  }, [user]);

  return (
    <Welcome formSubmitHandler={formSubmitHandler}>
      <div className={s.wrapper}>
        <div className={s.inputs}>
          <div className={s.selectWrapper}>
            <MajorSelect
              items={selectOptions}
              value={chosenRole}
              onChange={handleChange}
              placeholder="Choose who you are"
              displayEmpty
            />
          </div>
          {chosenRole === "Influencer" && (
            <textarea
              className={s.textarea}
              value={writtenSocials}
              onChange={handleSocialsWrite}
              placeholder="Write links to your social networks"
            />
          )}
        </div>
        {chosenRole !== "Holder" && (
          <div className={s.submitWrapper}>
            <Button type="submit" disabled={!chosenRole || isLoading}>
              {isLoading ? (
                <div className={s.loaderWrapper}>
                  <Loader
                    type="line-scale-pulse-out-rapid"
                    active
                    innerClassName={s.loader}
                  />
                </div>
              ) : (
                t("extra.submit")
              )}
            </Button>
          </div>
        )}
        {chosenRole === "Holder" && (
          <button
            type="button"
            onClick={connectTelegramHandler}
            className={s.connectTelegramWrapper}
          >
            <ButtonLink
              link={`${endpoint}profile/connect/telegram?token=${token}`}
              isRoutingButton={false}
              className={s.buttonLink}
            >
              {userRevalidateInterval ? (
                <div className={s.loaderWrapper}>
                  <Loader
                    type="line-scale-pulse-out-rapid"
                    active
                    innerClassName={s.loader}
                  />
                </div>
              ) : (
                "CHECK in telegram"
              )}
            </ButtonLink>
          </button>
        )}
      </div>
    </Welcome>
  );
}

export default ExtraUserInputsPage;
