import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { Link, Outlet, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "utils/firebase";

import useGetUser from "hooks/query/useGetUser";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";

import SideNavigation from "components/SideNavigation";
import SideBanners from "components/SideBanners";
import FAQ from "components/FAQ";
import ExplanationModal from "components/Modal/ExplanationModal";
import ReferralAccessModal from "components/Modal/ReferralAccessModal";
import Header from "./Header";
import MobileBottom from "./MobileBottom";

import s from "./MainLayout.module.scss";

function MainLayout() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const { data: user } = useGetUser();
  const showSnackBar = useShowSnackBar();

  const [isFAQOpen, setIsFAQOpen] = useState(false);
  const [explanationModalOpen, setExplanationModalOpen] =
    useState<boolean>(false);
  const [referralModalOpen, setReferralModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const isModalSeen =
      localStorage.getItem("isExplanationModalSeen") || "false";
    if (isModalSeen === "false") {
      setExplanationModalOpen(true);
    }
  }, [auth]);

  const closeExplanationModal = () => {
    localStorage.setItem("isExplanationModalSeen", "true");
    setExplanationModalOpen(false);
  };
  const closeReferralModal = () => {
    setReferralModalOpen(false);
  };

  const FAQOpen = () => {
    setIsFAQOpen(true);
  };
  const FAQClose = () => {
    setIsFAQOpen(false);
  };

  const isSettings = useMemo(() => {
    return pathname === "/profile";
  }, [pathname]);

  const sideNavigationElements = useMemo(
    () => [
      {
        link: "/",
        title: t("navigation.missions"),
        icon: <span className="icon-mission" />,
      },
      {
        link: "/referral",
        title: t("navigation.referral"),
        icon: <span className="icon-people" />,
        isBlocked: !user?.is_ref_enabled,
        onClick: !user?.is_ref_enabled
          ? () => {
              setReferralModalOpen(true);
            }
          : null,
      },
      {
        link: "/referral",
        title: "promo codes",
        icon: <span className="icon-present" />,
        isBlocked: true,
        onClick: () =>
          showSnackBar({
            title: t("notification.soon.title"),
            text: t("notification.soon.text"),
          }),
      },
      {
        link: "/inventory",
        title: "inventory",
        icon: <span className="icon-inventory" />,
      },
    ],
    [t, user],
  );

  const sideNavigationElementsSettings = useMemo(
    () => [
      {
        link: "/",
        title: t("navigation.back"),
        icon: <span className="icon-back" />,
        isActive: true,
      },
      {
        onClick: async () => {
          await signOut(auth);
          queryClient.invalidateQueries({ queryKey: ["user"] });
        },
        title: t("navigation.logOut"),
        icon: <span className="icon-logOut" />,
        notActive: true,
      },
    ],
    [auth, t],
  );

  return (
    <div className={s.layout}>
      <Header />
      <div className={s.content}>
        <div className={s.leftSide}>
          <SideNavigation
            elements={
              isSettings
                ? sideNavigationElementsSettings
                : sideNavigationElements
            }
          />
          <div className={s.sideBannersMobile}>
            <SideBanners type="mobile" />
          </div>
        </div>
        <div className={s.middlePart}>
          <Outlet />
        </div>
        <div className={s.rightSide}>
          <SideBanners />
        </div>
      </div>
      <div className={s.FAQ}>
        <button type="button" onClick={FAQOpen} className={s.FAQButton}>
          ?
        </button>
        <div className={`${s.FAQForm} ${isFAQOpen ? s.open : ""}`}>
          <div className={s.topPart}>
            <div className={s.question}>?</div>
            <div className={s.title}>FAQ</div>
          </div>
          <button type="button" onClick={FAQClose} className={s.close}>
            <span className="icon-close" />
          </button>
          <FAQ />
        </div>
      </div>
      <MobileBottom setReferralModalOpen={setReferralModalOpen} />
      <ExplanationModal
        open={explanationModalOpen}
        close={closeExplanationModal}
      />
      <ReferralAccessModal
        open={referralModalOpen}
        close={closeReferralModal}
      />
    </div>
  );
}

export default MainLayout;
