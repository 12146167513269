import { useMutation, useQueryClient } from "@tanstack/react-query";
import { changeName } from "api/user";
import getFirebaseIdToken from "utils/getFirebaseIdToken";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";
import { useTranslation } from "react-i18next";

interface Payload {
  name: string;
}

const useChangeUser = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const showSnackBar = useShowSnackBar();

  return useMutation<void, Error, Payload>(
    ["change-user"],
    async ({ name }) => {
      const token = await getFirebaseIdToken();

      if (token && name) {
        return changeName(token, { name });
      }

      if (name == null) return Promise.reject(new Error("Invalid name"));
      return Promise.reject(new Error("Invalid Firebase ID Token"));
    },
    {
      onSuccess: () => {
        showSnackBar({
          title: t("notification.nameChanged.title"),
          text: t("notification.nameChanged.text"),
        });
        return queryClient.invalidateQueries({ queryKey: ["user"] });
      },
      onError: () => {
        showSnackBar({
          title: t("notification.commonError.title"),
          text: t("notification.commonError.text"),
          variant: "error",
        });
      },
    },
  );
};

export default useChangeUser;
