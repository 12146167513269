import React from "react";

import s from "./NoPage.module.scss";

function NoPage() {
  return (
    <div className={s.noPage}>
      <span>404</span>
      <span>There is no page like this :(</span>
    </div>
  );
}

export default NoPage;
