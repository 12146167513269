import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./i18n/config";
import { SnackbarProvider } from "notistack";
import Authorizer from "components/helpers/Authorizer";
import LangInitializer from "components/helpers/LangInitializer";
import HeightSetter from "components/helpers/HeightSetter";
import { AuthContextProvider } from "contexts/AuthContext";
import Router from "./Router";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        maxSnack={3}
        classes={{
          containerRoot: "snackBarContainer",
        }}
      >
        <AuthContextProvider>
          <Authorizer />
          <LangInitializer />
          <HeightSetter />
          <Router />
        </AuthContextProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
