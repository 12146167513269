import { Gift, InventoryItem } from "types/inventory";
import { ApiError } from "types/error";

const endpoint = process.env.REACT_APP_API_ENDPOINT || "";

export const getInventory = async (token: string): Promise<InventoryItem[]> => {
  const response = await fetch(`${endpoint}profile/inventory`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    let errorData: ApiError;
    try {
      errorData = await response.json();
    } catch (error) {
      throw new Error("API error");
    }
    throw new Error(errorData?.message);
  }

  return response.json();
};

export const getGift = async (token: string): Promise<Gift> => {
  const response = await fetch(`${endpoint}profile/inventory/receive-gift`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    let errorData: ApiError;
    try {
      errorData = await response.json();
    } catch (error) {
      throw new Error("API error");
    }
    throw new Error(errorData?.message);
  }

  return response.json();
};
