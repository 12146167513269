import React from "react";

import Button from "components/Button";

import giftImage from "images/gift.png";

import s from "./GiftBlock.module.scss";

interface Props {
  isAvailable: boolean;
  openLootBox: () => void;
}

function GiftBlock({ isAvailable, openLootBox }: Props) {
  return (
    <div className={s.giftBlock}>
      <div className={s.background}>
        <img src={giftImage} alt="gift" className="fill" />
      </div>
      <Button
        className={`${s.button} ${isAvailable ? s.active : ""}`}
        onClick={openLootBox}
        disabled={!isAvailable}
      >
        Get a gift
      </Button>
      <span className={s.dayNumber}>Day 7</span>
    </div>
  );
}

export default GiftBlock;
