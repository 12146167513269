import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Codename, TaskCategory, MissionData } from "types/task";

import useCheckTask from "hooks/query/useCheckTask";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";

import ButtonLink from "components/ButtonLink";
import Button from "components/Button";

import s from "./Mission.module.scss";

interface Props {
  variant: TaskCategory | "completed";
  cost: number;
  missionData: MissionData;
  codename: Codename;
}

function Mission({ variant, cost, missionData, codename }: Props) {
  const { t } = useTranslation();
  const showSnackBar = useShowSnackBar();

  const [afterTask, setAfterTask] = useState(false);
  const [text, setText] = useState("");
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const [checkingTask, setCheckingTask] = useState<boolean>(false);

  const { mutateAsync: checkTask } = useCheckTask();

  const checkTaskHandler = async () => {
    try {
      setCheckingTask(true);
      await checkTask({
        codename,
        delayTime: 8000,
        taskName: missionData.title,
        worth: cost,
        result: text,
        file: imageFile,
      });
    } catch (error) {
      showSnackBar({
        title: t("notification.commonError.title"),
        text: `${error}`,
        variant: "error",
      });
    } finally {
      setCheckingTask(false);
    }
  };

  const imageUrl = useMemo(() => {
    if (imageFile) {
      return URL.createObjectURL(imageFile);
    }
    return null;
  }, [imageFile]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
    }
  };

  const handleImageDelete = () => {
    setImageFile(undefined);
  };

  const handleTextChange = (
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    setText(e.target.value);
  };

  const onTaskClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAfterTask(true);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    checkTaskHandler();
  };

  switch (variant) {
    case "link":
      return (
        <form className={s.mission} onSubmit={onSubmit}>
          <div className={s.topPart}>
            <div className={s.text}>{missionData.description}</div>
          </div>
          <input
            type="text"
            className={s.inputText}
            value={text}
            onChange={handleTextChange}
            placeholder="Put your link here"
          />
          <div className={s.buttons}>
            {missionData.link && (
              <button
                type="button"
                className={s.buttonWrapper}
                onClick={onTaskClick}
              >
                <ButtonLink link={missionData.link}>Download</ButtonLink>
              </button>
            )}
            <Button type="submit" className={s.submit}>
              {checkingTask ? "Checking" : "send"}
            </Button>
          </div>
        </form>
      );
    case "image":
      return (
        <form className={s.mission} onSubmit={onSubmit}>
          <div className={s.topPart}>
            <div className={s.text}>{missionData.description}</div>
          </div>
          {imageUrl && (
            <div className={s.images}>
              <div className={s.imageWrapper}>
                <button
                  type="button"
                  className={s.close}
                  onClick={handleImageDelete}
                >
                  <span className="icon-close" />
                </button>
                <img src={imageUrl} className="fill" alt="" />
              </div>
            </div>
          )}
          <div className={s.buttons}>
            {missionData.link && (
              <button
                type="button"
                className={s.buttonWrapper}
                onClick={onTaskClick}
              >
                <ButtonLink link={missionData.link}>Download</ButtonLink>
              </button>
            )}
            <label className={s.buttonInput}>
              Upload
              <input
                type="file"
                className={s.fileInput}
                onChange={handleImageChange}
              />
            </label>

            <Button type="submit" className={s.submit}>
              {checkingTask ? "Checking" : "send"}
            </Button>
          </div>
        </form>
      );
    case "short_text":
      return (
        <form className={s.mission} onSubmit={onSubmit}>
          <div className={s.topPart}>
            <div className={s.text}>{missionData.description}</div>
          </div>
          <input
            type="text"
            className={s.inputText}
            value={text}
            onChange={handleTextChange}
            placeholder="Put your answer here"
          />
          <div className={s.buttons}>
            {missionData.link && (
              <button
                type="button"
                className={s.buttonWrapper}
                onClick={onTaskClick}
              >
                <ButtonLink link={missionData.link}>Download</ButtonLink>
              </button>
            )}
            <Button type="submit" className={s.submit}>
              {checkingTask ? "Checking" : "send"}
            </Button>
          </div>
        </form>
      );
    case "long_text":
      return (
        <form className={s.mission} onSubmit={onSubmit}>
          <div className={s.topPart}>
            <div className={s.text}>{missionData.description}</div>
          </div>
          <textarea
            className={`${s.inputLongText} ${s.inputText}`}
            value={text}
            onChange={handleTextChange}
            placeholder="Tell us about yourself"
          />
          <div className={s.buttons}>
            {missionData.link && (
              <button
                type="button"
                className={s.buttonWrapper}
                onClick={onTaskClick}
              >
                <ButtonLink link={missionData.link}>Download</ButtonLink>
              </button>
            )}
            <Button type="submit" className={s.submit}>
              {checkingTask ? "Checking" : "send"}
            </Button>
          </div>
        </form>
      );
    case "completed":
      return (
        <div className={`${s.mission} ${s.completed}`}>
          <div className={s.leftPart}>
            <div className={s.check}>
              <span className="icon-check" />
            </div>
            <div className={s.topPart}>
              <div className={s.text}>{missionData.description}</div>
            </div>
          </div>
        </div>
      );

    default:
      return <div>Mission</div>;
  }
}

export default Mission;
