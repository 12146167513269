import { useEffect, useContext, useCallback } from "react";
import { onAuthStateChanged } from "firebase/auth";
import AuthContext from "contexts/AuthContext";
import { auth } from "utils/firebase";

function Authorizer() {
  const { isAuthInitialized, setIsAuthInitialized } = useContext(AuthContext);

  const handleAuthStateChanged = useCallback(() => {
    if (isAuthInitialized) return;
    setIsAuthInitialized(true);
  }, [isAuthInitialized]);

  useEffect(() => {
    return onAuthStateChanged(auth, handleAuthStateChanged);
  }, []);

  return null;
}

export default Authorizer;
