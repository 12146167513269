import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import s from "./SideNavigation.module.scss";

interface Element {
  title: string;
  icon: React.ReactNode;
  link?: string;
  isActive?: boolean;
  grayIcon?: boolean;
  isBlocked?: boolean;
  onClick?: (() => void) | null;
}

interface SideNavigationProps {
  elements: Element[];
  className?: string;
}

function SideNavigation({ elements, className = "" }: SideNavigationProps) {
  const { pathname } = useLocation();

  const navigationClassName = (elem: Element) => {
    return `${s.navigation}  ${
      pathname === elem.link || elem.isActive ? s.active : ""
    } ${elem.grayIcon ? s.grayIcon : ""}`;
  };

  return (
    <div className={`${s.sideNavigation} ${className}`}>
      {elements.map((elem) => {
        if (!elem?.onClick)
          return (
            <div className={navigationClassName(elem)} key={elem.title}>
              <div className={s.iconWrapper}>
                {elem.isBlocked ? (
                  <>
                    <span className={s.icon}>{elem.icon}</span>
                    <span className={`icon-lock ${s.lock}`} />
                  </>
                ) : (
                  elem.icon
                )}
              </div>
              <span className={s.title}>{elem.title}</span>
              {!elem.isBlocked && (
                <NavLink to={elem.link || "/"} className={s.link} />
              )}
            </div>
          );
        return (
          <button
            type="button"
            onClick={elem?.onClick}
            className={navigationClassName(elem)}
            key={elem.title}
          >
            <div className={s.iconWrapper}>
              {elem.isBlocked ? (
                <>
                  <span className={s.icon}>{elem.icon}</span>
                  <span className={`icon-lock ${s.lock}`} />
                </>
              ) : (
                elem.icon
              )}
            </div>
            <span className={s.title}>{elem.title}</span>
          </button>
        );
      })}
    </div>
  );
}

export default SideNavigation;
