import { useMutation } from "@tanstack/react-query";
import { sendEmail } from "api/auth";

interface Payload {
  email: string;
}

const useSendEmail = () => {
  return useMutation<void, Error, Payload>(
    ["send-email"],
    async ({ email }) => {
      if (email) {
        return sendEmail({ email });
      }

      if (email == null) return Promise.reject(new Error("Invalid email"));
      return Promise.reject(new Error("Invalid"));
    },
    {
      onSuccess: () => {
        return true;
      },
    },
  );
};

export default useSendEmail;
