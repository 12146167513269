import React, { useMemo, useState } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import { useTranslation } from "react-i18next";

import useGetUser from "hooks/query/useGetUser";
import useChangeWallet from "hooks/query/useChangeWallet";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";

import Reward from "components/Reward";

import s from "./MetaMask.module.scss";

function MetaMask() {
  const { t } = useTranslation();
  const { data: user } = useGetUser();
  const showSnackBar = useShowSnackBar();
  const [metaMaskLoading, setMetaMaskLoading] = useState(false);

  const { mutate: changeWallet } = useChangeWallet();

  const metaMaskClassName = useMemo(
    () => `${s.metaMask} ${user?.wallet_address ? "" : s.metaMaskNoWallet}`,
    [user],
  );

  const clickHandler = async () => {
    setMetaMaskLoading(true);
    const provider = await detectEthereumProvider();
    if (provider) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      if (account) {
        changeWallet({ wallet_address: account });
      }
      showSnackBar({
        title: t("notification.walletConnect.title"),
        text: t("notification.walletConnect.text"),
      });
    } else {
      showSnackBar({
        title: t("notification.metaMaskError.title"),
        text: t("notification.metaMaskError.text"),
        variant: "error",
      });
    }
    setMetaMaskLoading(false);
  };

  return (
    <div className={s.wrapper}>
      <button
        type="button"
        className={metaMaskClassName}
        onClick={clickHandler}
        disabled={metaMaskLoading}
      >
        <div className={s.iconWrapper}>
          <span className="icon-metaMask" />
        </div>
        <span className={s.value}>
          {user?.wallet_address || "CONNECT WALLET"}
        </span>
      </button>
      {!user?.wallet_address && <Reward cost={5} />}
    </div>
  );
}

export default MetaMask;
