/* eslint-disable eqeqeq */
import React, { useState } from "react";

import useGetTasks from "hooks/query/useGetTasks";

import Timer from "components/Timer";
import SocialModal from "components/Modal/SocialModal";
import EarnedPanel from "components/EarnedPanel";
import DailyMission from "components/DailyMission";

import s from "./MissionsPage.module.scss";

type Vendor = "twitter" | "telegram" | "discord";

function MissionsPage() {
  const { data: tasks } = useGetTasks();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalSocial, setModalSocial] = useState<Vendor | "">("");

  const [expanded, setExpanded] = useState<string | false>("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className={s.missionsPage}>
      <div className={s.timerWrapper}>
        <Timer />
      </div>
      <EarnedPanel />
      <div className={s.accordions}>
        {tasks?.map((elem) => (
          <DailyMission
            expanded={expanded}
            key={elem.codename}
            codename={elem.codename}
            category={elem.result_type}
            completed={elem.is_user_completed}
            cost={elem.award}
            handleChange={handleChange}
          />
        ))}
      </div>
      <SocialModal
        open={modalOpen}
        setOpen={setModalOpen}
        social={modalSocial}
      />
    </div>
  );
}

export default MissionsPage;
