import React, { useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "layouts";
import ProfilePage from "pages/ProfilePage";
import ReferralPage from "pages/ReferralPage";
import AuthorizationPage from "pages/AuthorizationPage";
import MissionsPage from "pages/MissionsPage";
import useGetUser from "hooks/query/useGetUser";
import LoadingPage from "pages/LoadingPage";
import FAQPage from "pages/FAQPage";
import NoPage from "pages/NoPage";
import ExtraUserInputsPage from "pages/ExtraUserInputsPage";
import InventoryPage from "pages/InventoryPage";

function Router() {
  const { data: user, isLoading } = useGetUser();

  const routes = useMemo(() => {
    if (isLoading) return <Route path="/*" element={<LoadingPage />} />;
    if (user)
      if (user.role) {
        return (
          <Route path="/*" element={<MainLayout />}>
            <Route index element={<MissionsPage />} />
            <Route path="profile" element={<ProfilePage />} />
            {user.is_ref_enabled && (
              <Route path="referral" element={<ReferralPage />} />
            )}
            <Route path="inventory" element={<InventoryPage />} />
            <Route path="faq" element={<FAQPage />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        );
      } else {
        return <Route path="/*" element={<ExtraUserInputsPage />} />;
      }
    return <Route path="/*" element={<AuthorizationPage />} />;
  }, [user, isLoading]);

  return (
    <BrowserRouter>
      <Routes>{routes}</Routes>
    </BrowserRouter>
  );
}

export default Router;
