import React, { useEffect, useMemo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import Loader from "react-loaders";

import { Gift, InventoryCodeName } from "types/inventory";

import useCloseModal from "hooks/modal/useCloseModal";
import useGetGift from "hooks/query/useGetGift";

import giftImage from "images/gift.png";

import Button from "components/Button";
import ButtonLink from "components/ButtonLink";
import InventoryItem from "components/InventoryItem";
import ModalContainer from "../ModalContainer";

import "swiper/css";
import s from "./LootBoxModal.module.scss";

interface Props {
  open: boolean;
  close: () => void;
}

const getGiftSlide = (codename: InventoryCodeName) => {
  switch (codename) {
    case "ace_5":
      return 50;
    case "ace_10":
      return 52;
    case "ace_15":
      return 47;
    case "nft_car_tier_1":
      return 54;
    case "nft_pass":
      return 49;
    case "nft_box":
      return 53;
    case "alpha_invite":
      return 48;
    case "refferal_invite":
      return 51;
    case "lootbox_reroll":
      return 46;
    default:
      return 0;
  }
};

const getTitle = (codename: InventoryCodeName | undefined) => {
  switch (codename) {
    case "ace_5":
      return "you have earned 5 ace";
    case "ace_10":
      return "you have earned 10 ace";
    case "ace_15":
      return "you have earned 15 ace";
    case "nft_car_tier_1":
      return "you have earned trace merch";
    case "nft_pass":
      return "you have earned nft container";
    case "nft_box":
      return "you have earned nft box";
    case "alpha_invite":
      return "you have earned invite to alpha";
    case "refferal_invite":
      return "you have earned invite to refferal";
    case "lootbox_reroll":
      return "you have earned reroll";
    default:
      return "spin the drum and try your luck";
  }
};

const slidesElements = [1, 2, 3, 4, 5, 6].map((elem) => (
  <React.Fragment key={elem}>
    <SwiperSlide className={s.slide} key={`${elem} nft_pass`}>
      <div className={s.slideContent}>
        <InventoryItem variant="nft_pass" noShadow />
      </div>
    </SwiperSlide>
    <SwiperSlide className={s.slide} key={`${elem} ace_5`}>
      <div className={s.slideContent}>
        <InventoryItem variant="ace_5" noShadow />
      </div>
    </SwiperSlide>
    <SwiperSlide className={s.slide} key={`${elem} refferal_invite`}>
      <div className={s.slideContent}>
        <InventoryItem variant="refferal_invite" noShadow />
      </div>
    </SwiperSlide>
    <SwiperSlide className={s.slide} key={`${elem} ace_10`}>
      <div className={s.slideContent}>
        <InventoryItem variant="ace_10" noShadow />
      </div>
    </SwiperSlide>
    <SwiperSlide className={s.slide} key={`${elem} nft_box`}>
      <div className={s.slideContent}>
        <InventoryItem variant="nft_box" noShadow />
      </div>
    </SwiperSlide>
    <SwiperSlide className={s.slide} key={`${elem} nft_car_tier_1`}>
      <div className={s.slideContent}>
        <InventoryItem variant="nft_car_tier_1" noShadow />
      </div>
    </SwiperSlide>
    <SwiperSlide className={s.slide} key={`${elem} lootbox_reroll`}>
      <div className={s.slideContent}>
        <InventoryItem variant="lootbox_reroll" noShadow />
      </div>
    </SwiperSlide>
    <SwiperSlide className={s.slide} key={`${elem} ace_15`}>
      <div className={s.slideContent}>
        <InventoryItem variant="ace_15" noShadow />
      </div>
    </SwiperSlide>
    <SwiperSlide className={s.slide} key={`${elem} alpha_invite`}>
      <div className={s.slideContent}>
        <InventoryItem variant="alpha_invite" noShadow />
      </div>
    </SwiperSlide>
  </React.Fragment>
));

function LootBoxModal({ open, close }: Props) {
  const { isClosing, onClose } = useCloseModal(close);
  const [swiper, setSwiper] = useState<SwiperCore | undefined>(undefined);
  const { mutateAsync: sendRequest, isLoading } = useGetGift();

  const [won, setWon] = useState<boolean>(false);
  const [isInitial, setIsInitial] = useState<boolean>(true);
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const [gift, setGift] = useState<Gift | null>(null);

  const startLooting = async () => {
    if (!swiper) return;

    const result = await sendRequest();

    const numberItem = getGiftSlide(result.codename);

    setGift(result);

    setIsSpinning(true);
    swiper.slideTo(numberItem);
  };

  const onWon = () => {
    if (isInitial) {
      setIsInitial(false);
      return;
    }
    setIsSpinning(false);
    setWon(true);
  };

  const title = useMemo(() => getTitle(gift?.codename), [won]);

  return (
    <ModalContainer open={open} onClose={onClose} isClosing={isClosing}>
      <div className={s.modalWindowWrapper}>
        <div className={s.modalWindow}>
          <div className={s.summeryWrapper}>
            <div className={s.summery}>
              <div className={s.title}>weekly gift lottery</div>
              <button type="button" className={s.close} onClick={onClose}>
                <span className="icon-close" />
              </button>
            </div>
          </div>
          <div className={s.content}>
            <div className={s.title}>{title}</div>
            <div className={`${s.swiperWrapper} ${won ? `won ${s.won}` : ""}`}>
              <Swiper
                className={s.swiper}
                onSlideChangeTransitionEnd={onWon}
                slidesPerView={2}
                spaceBetween={15}
                centeredSlides
                noSwiping
                noSwipingClass="swiper-slide"
                loop
                speed={5000}
                onSwiper={(instance) => setSwiper(instance)}
                breakpoints={{
                  760: {
                    slidesPerView: 4,
                  },
                  640: {
                    slidesPerView: 3.5,
                  },
                  500: {
                    slidesPerView: 2.5,
                  },
                  350: {
                    slidesPerView: 2,
                  },
                }}
              >
                {slidesElements}
              </Swiper>
              <div className={s.shadow} />
            </div>
            {!won && (
              <Button
                className={`${s.button} ${isSpinning ? s.disabled : ""}`}
                type="button"
                onClick={startLooting}
                disabled={isLoading || isSpinning}
              >
                {isLoading && (
                  <div className={s.loaderWrapper}>
                    <Loader
                      type="line-scale-pulse-out-rapid"
                      active
                      innerClassName={s.loader}
                    />
                  </div>
                )}
                {!isLoading && "Start"}
              </Button>
            )}
            {won && (
              <ButtonLink
                isRoutingButton
                link="/inventory"
                className={s.button}
              >
                Receive
              </ButtonLink>
            )}
            <div className={s.imageWrapper}>
              <img src={giftImage} alt="gift" className="fill" />
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

export default LootBoxModal;
