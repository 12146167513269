import React, { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent, SnackbarKey } from "notistack";

import s from "./SnackBar.module.scss";

interface SnackBarProps {
  id: SnackbarKey;
  title: React.ReactNode;
  message: React.ReactNode;
  variant: "error" | "default";
}

const SnackBar = forwardRef(
  (
    { id, title, message, variant }: SnackBarProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const snackBarClass = `${s.snackBar} ${variant === "error" ? s.error : ""}`;

    return (
      <SnackbarContent ref={ref} className={snackBarClass} >
        <div className={s.content}>
          {title && <div className={s.title}>{title}</div>}
          {message && <div className={s.message}>{message}</div>}
        </div>
        <button type="button" className={s.closeButton} onClick={handleDismiss}>
          <span className="icon-close" />
        </button>
      </SnackbarContent>
    );
  },
);

export default SnackBar;
