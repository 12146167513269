import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { useTranslation } from "react-i18next";

import useCloseModal from "hooks/modal/useCloseModal";

import ModalContainer from "../ModalContainer";

import explanation1 from "../../../images/explanation1.png";
import explanation2 from "../../../images/explanation2.png";
import explanation3 from "../../../images/explanation3.png";
import explanation1Mobile from "../../../images/explanation1Mobile.png";
import explanation2Mobile from "../../../images/explanation2Mobile.png";
import explanation3Mobile from "../../../images/explanation3Mobile.png";

import s from "./ExplanationModal.module.scss";
import "swiper/css";
import "swiper/css/pagination";

interface Props {
  open: boolean;
  close: () => void;
}

function ExplanationModal({ open, close }: Props) {
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState<SwiperCore>();
  const { isClosing, onClose } = useCloseModal(close);

  const arrowNextHandler = () => {
    swiper?.slideNext();
  };

  const arrowPrevHandler = () => {
    swiper?.slidePrev();
  };

  return (
    <ModalContainer open={open} onClose={onClose} isClosing={isClosing}>
      <div className={s.modalWindowWrapper}>
        <div className={s.modalWindow}>
          <Swiper
            onSwiper={setSwiper}
            pagination={{
              bulletActiveClass: `swiper-pagination-bullet-active ${s.bulletActive}`,
              horizontalClass: `swiper-pagination-horizontal ${s.pagination}`,
            }}
            modules={[Pagination]}
            spaceBetween={15}
            grabCursor
          >
            <SwiperSlide className={s.slide}>
              <div className={s.summeryWrapper}>
                <div className={s.summery}>
                  <div className={s.title}>
                    {t("explanation.firstSlide.summery")}
                  </div>
                  <button type="button" className={s.close} onClick={onClose}>
                    <span className="icon-close" />
                  </button>
                </div>
              </div>
              <div className={s.content}>
                <div className={s.title}>
                  {t("explanation.firstSlide.title")}
                </div>
                <div className={s.description}>
                  {t("explanation.firstSlide.text")}
                </div>
                <div className={s.bottomPart}>
                  <button
                    type="button"
                    className={s.arrowPrev}
                    onClick={arrowPrevHandler}
                  >
                    <span className="icon-arrowDown" />
                  </button>
                  <div className={s.imgWrapper}>
                    <img
                      src={explanation1}
                      className={s.img}
                      alt="explanation one"
                    />
                  </div>
                  <div className={s.imgWrapperMobile}>
                    <img
                      src={explanation1Mobile}
                      className={s.img}
                      alt="explanation one"
                    />
                  </div>
                  <button
                    type="button"
                    className={s.arrowNext}
                    onClick={arrowNextHandler}
                  >
                    <span className="icon-arrowDown" />
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={s.slide}>
              <div className={s.summeryWrapper}>
                <div className={s.summery}>
                  <div className={s.title}>
                    {t("explanation.secondSlide.summery")}
                  </div>
                  <button type="button" className={s.close} onClick={onClose}>
                    <span className="icon-close" />
                  </button>
                </div>
              </div>
              <div className={s.content}>
                <div className={s.title}>
                  {t("explanation.secondSlide.title")}
                </div>
                <div className={s.description}>
                  {t("explanation.secondSlide.text")}
                </div>
                <div className={s.bottomPart}>
                  <button
                    type="button"
                    className={s.arrowPrev}
                    onClick={arrowPrevHandler}
                  >
                    <span className="icon-arrowDown" />
                  </button>
                  <div className={s.imgWrapper}>
                    <img
                      src={explanation2}
                      className={s.img}
                      alt="explanation two"
                    />
                  </div>
                  <div className={s.imgWrapperMobile}>
                    <img
                      src={explanation2Mobile}
                      className={s.img}
                      alt="explanation two"
                    />
                  </div>
                  <button
                    type="button"
                    className={s.arrowNext}
                    onClick={arrowNextHandler}
                  >
                    <span className="icon-arrowDown" />
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={s.slide}>
              <div className={s.summeryWrapper}>
                <div className={s.summery}>
                  <div className={s.title}>
                    {t("explanation.thirdSlide.summery")}
                  </div>
                  <button type="button" className={s.close} onClick={onClose}>
                    <span className="icon-close" />
                  </button>
                </div>
              </div>
              <div className={s.content}>
                <div className={s.title}>
                  {t("explanation.thirdSlide.title")}
                </div>
                <div className={s.description}>
                  {t("explanation.thirdSlide.text")}
                  <br />
                  <br />
                  {t("explanation.thirdSlide.text2")}
                </div>
                <div className={s.bottomPart}>
                  <button
                    type="button"
                    className={s.arrowPrev}
                    onClick={arrowPrevHandler}
                  >
                    <span className="icon-arrowDown" />
                  </button>
                  <div className={s.imgWrapper}>
                    <img
                      src={explanation3}
                      className={s.img}
                      alt="explanation three"
                    />
                  </div>
                  <div className={s.imgWrapperMobile}>
                    <img
                      src={explanation3Mobile}
                      className={s.img}
                      alt="explanation three"
                    />
                  </div>
                  <button
                    type="button"
                    className={s.arrowNext}
                    onClick={arrowNextHandler}
                  >
                    <span className="icon-arrowDown" />
                  </button>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </ModalContainer>
  );
}

export default ExplanationModal;
