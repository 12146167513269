import React, { useState, useMemo } from "react";
import AuthContext, { AuthContextInterface } from "./AuthContext";

interface AuthContextProviderProps {
  children?: React.ReactNode;
}

function AuthContextProvider({
  children = undefined,
}: AuthContextProviderProps) {
  const [isAuthInitialized, setIsAuthInitialized] = useState<boolean>(false);

  const authContextValue = useMemo<AuthContextInterface>(() => {
    return { isAuthInitialized, setIsAuthInitialized };
  }, [isAuthInitialized]);

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
