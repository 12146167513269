import React from "react";
import { useSnackbar } from "notistack";
import SnackBar from "components/SnackBar";

interface Props {
  title?: React.ReactNode;
  text?: React.ReactNode;
  variant?: "error" | "default";
}

const useShowSnackBar = () => {
  const { enqueueSnackbar } = useSnackbar();

  return ({ title = "", text = "", variant = "default" }: Props) => {
    enqueueSnackbar(text, {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      content: (key, message) => (
        <SnackBar id={key} message={message} title={title} variant={variant} />
      ),
    });
  };
};

export default useShowSnackBar;
