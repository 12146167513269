import { ApiError } from "types/error";
import { CreateUserData, TokenResponse, User } from "../types/user";

const endpoint = process.env.REACT_APP_API_ENDPOINT || "";

export const getUser = async (token: string): Promise<User> => {
  const response = await fetch(`${endpoint}profile`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    let errorData: ApiError;
    try {
      errorData = await response.json();
    } catch (error) {
      throw new Error("API error");
    }
    throw new Error(errorData?.message);
  }

  return response.json();
};

export const createUser = async (
  token: string,
  data: CreateUserData,
): Promise<void> => {
  const body = JSON.stringify(data);

  const response = await fetch(`${endpoint}signup`, {
    method: "POST",
    body,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    let errorData: ApiError;
    try {
      errorData = await response.json();
    } catch (error) {
      throw new Error("API error");
    }
    throw new Error(errorData?.message);
  }
};

export const sendEmail = async (data: { email: string }): Promise<void> => {
  const body = JSON.stringify(data);

  const response = await fetch(`${endpoint}signup/mail`, {
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    let errorData: ApiError;
    try {
      errorData = await response.json();
    } catch (error) {
      throw new Error("API error");
    }
    throw new Error(errorData?.message);
  }
};

interface SendCodeParams {
  email: string;
  code: string;
}

export const sendCode = async (
  data: SendCodeParams,
): Promise<TokenResponse> => {
  const body = JSON.stringify(data);

  const response = await fetch(`${endpoint}signup/mail/code`, {
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    let errorData: ApiError;
    try {
      errorData = await response.json();
    } catch (error) {
      throw new Error("API error");
    }
    throw new Error(errorData?.message);
  }
  return response.json();
};
