import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useGetUser from "hooks/query/useGetUser";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";

import doubleReward from "images/doubleReward.png";

import s from "./ReferralPage.module.scss";

function ReferralPage() {
  const { t } = useTranslation();
  const showSnackBar = useShowSnackBar();
  const { data: user } = useGetUser();

  const referralLink = useMemo(
    () => `https://referral.trace.top/?r=${user?.hash}`,
    [user],
  );

  const copy = () => {
    navigator.clipboard.writeText(referralLink);
    showSnackBar({
      title: t("notification.linkCopied.title"),
      text: "notification.linkCopied.text",
    });
  };

  return (
    <div className={s.referralPage}>
      <div className={s.userStats}>
        <div className={s.user}>
          <div className={s.referralLinkTitle}>{t("referral.link")}</div>
          <div className={s.referralLink}>
            <input
              readOnly
              type="text"
              value={`referral.trace.top/?r=${user?.hash}`}
              className={s.referralLinkValue}
            />
            <button type="button" onClick={copy} className={s.copyButton}>
              <span className="icon-copy" />
            </button>
          </div>
          <div className={s.tweetButton}>
            <span className="icon-twitter" />
            <span className={s.tweetValue}>{t("general.tweet")}</span>
            <a
              href={`http://twitter.com/share?text=Join our community and earn ACE!&url=${referralLink}`}
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              tweet
            </a>
          </div>
        </div>
        <div className={`${s.tokens} ${s.panel}`}>
          <div className={s.title}>{t("referral.tokensReceived")}</div>
          <div className={s.valueContainer}>
            <div className={s.balanceIconWrapper}>
              <span className="icon-balance" />
            </div>
            <div className={s.value}>{user?.earned_referrals}</div>
          </div>
          <div className={s.backgroundValue}>{user?.earned_referrals}</div>
        </div>
        <div className={`${s.invites} ${s.panel}`}>
          <div className={s.title}>{t("referral.invitedPeople")}</div>
          <div className={s.valueContainer}>
            <div className={s.value}>{user?.counts_referrals}</div>
          </div>
          <div className={s.backgroundValue}>{user?.counts_referrals}</div>
        </div>
        <div className={s.pay}>
          <span className="icon-lock" />
          <div className={s.title}>{t("referral.payOut")}</div>
        </div>
      </div>
      <div className={s.topBanner}>
        <img src={doubleReward} alt="" className={s.image} />
      </div>
      <div className={s.invite}>
        <div className={s.title}>{t("referral.invite")}</div>
        <div className={s.content}>
          {t("referral.p1")}
          <br />
          <br />
          {t("referral.p2")}
        </div>
      </div>
    </div>
  );
}

export default ReferralPage;
