import { useMutation } from "@tanstack/react-query";
import useLogIn from "hooks/query/useLogIn";
import useCreateUser from "hooks/query/useCreateUser";

const useInitializeAccount = ({ hash }: { hash?: string }) => {
  const { mutateAsync: logIn } = useLogIn();
  const { mutateAsync: createUser } = useCreateUser();

  return useMutation<void, Error, void>(["initialize-account"], async () => {
    const { user } = await logIn();
    return createUser({ name: user.displayName, email: user.email, hash });
  });
};

export default useInitializeAccount;
