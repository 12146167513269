import React, { useMemo } from "react";

import s from "./Reward.module.scss";

interface RewardProps {
  cost: number;
  isCompleted?: boolean;
  mainClass?: string;
  plusClass?: string;
  iconClass?: string;
  aceClass?: string;
}

function Reward({
  cost,
  isCompleted = false,
  mainClass = "",
  plusClass = "",
  iconClass = "",
  aceClass = "",
}: RewardProps) {
  return (
    <div className={`${s.reward} ${mainClass}`}>
      {!isCompleted && <span className={`${s.plus} ${plusClass}`}>+</span>}
      <div className={`${s.balanceIcon} ${iconClass}`}>
        <span className="icon-balanceWrapped" />
      </div>
      <span className={`${s.ace} ${aceClass}`}>{cost} ACE</span>
    </div>
  );
}

export default Reward;
