import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Drawer } from "@mui/material";
import { signOut } from "firebase/auth";

import DrawerContent from "components/DrawerContent";

import { auth } from "utils/firebase";

import s from "./MobileBottom.module.scss";

interface Props {
  setReferralModalOpen: (value: React.SetStateAction<boolean>) => void;
}

function MobileBottom({ setReferralModalOpen }: Props) {
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const logOut = async () => {
    await signOut(auth);
    queryClient.invalidateQueries({ queryKey: ["user"] });
  };

  const toggleDrawer =
    (toggle: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(toggle);
    };

  const navClassName = (path: string) => {
    return `${s.navElement}  ${pathname === path ? s.active : ""}`;
  };

  return (
    <div className={s.mobileBottom}>
      <div className={s.container}>
        <Link to="/" className={navClassName("/")}>
          <span className={s.icon}>
            <span className="icon-mission" />
          </span>
          <span className={s.title}>daily tasks</span>
        </Link>
        <Link to="/inventory" className={navClassName("/inventory")}>
          <span className={s.icon}>
            <span className="icon-inventory" />
          </span>
          <span className={s.title}>inventory</span>
        </Link>
        <div className={s.navElement}>
          <div className={s.drawerWrapper}>
            <button
              type="button"
              onClick={toggleDrawer(!drawerOpen)}
              className={`${s.drawerButton} ${drawerOpen ? s.active : ""}`}
            >
              <span className={s.drawerLine} />
              <span className={s.drawerLine} />
              <span className={s.drawerLine} />
            </button>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
              PaperProps={{ style: { overflow: "hidden" } }}
            >
              <DrawerContent
                toggleDrawer={toggleDrawer}
                logOut={logOut}
                setReferralModalOpen={setReferralModalOpen}
              />
            </Drawer>
          </div>
          <span className={s.title}>Menu</span>
        </div>
      </div>
    </div>
  );
}

export default MobileBottom;
