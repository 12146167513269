import React, { useMemo } from "react";

import s from "./Switch.module.scss";

interface Props {
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  elements: string[];
}

function Switch({ currentIndex, setCurrentIndex, elements }: Props) {
  const tabClick = (tabIndex: number) => () => {
    setCurrentIndex(tabIndex);
  };

  const calcFillMovement = useMemo(
    () => ((currentIndex * 1) / elements.length) * 100,
    [elements, currentIndex],
  );

  return (
    <div className={s.switch}>
      <button
        type="button"
        className={`${s.tab} ${currentIndex === 0 ? s.currentTab : ""}`}
        onClick={tabClick(0)}
      >
        {elements[0]}
      </button>
      <button
        type="button"
        className={`${s.tab} ${currentIndex === 1 ? s.currentTab : ""}`}
        onClick={tabClick(1)}
      >
        {elements[1]}
      </button>
      <div className={s.fill} style={{ left: `${calcFillMovement}%` }} />
    </div>
  );
}

export default Switch;
