import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getUser } from "api/auth";
import AuthContext from "contexts/AuthContext";
import { User } from "types/user";
import getFirebaseIdToken from "utils/getFirebaseIdToken";

const useGetUser = (refetchInterval: number = 0) => {
  const { isAuthInitialized } = useContext(AuthContext);

  return useQuery<User | null, Error>(
    ["user"],
    async () => {
      const token = await getFirebaseIdToken();
      if (token) {
        return getUser(token);
      }
      return Promise.resolve(null);
    },
    {
      enabled: isAuthInitialized,
      refetchInterval,
    },
  );
};

export default useGetUser;
