/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import Reward from "components/Reward";

import s from "./Accordion.module.scss";

interface AccordionComponentProps {
  children: React.ReactNode;
  title: string;
  icon?: React.ReactNode;
  expanded: string | false;
  backgroundColor: string;
  uniqueKey?: string;
  backgroundColorShadow?: string;
  noExpandedIcon?: boolean;
  completed?: boolean;
  style?: React.CSSProperties;
  cost?: number;
  onChange?: (
    panel: string,
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const CustomAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderRadius: "25px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

function Accordion({
  children,
  expanded,
  title,
  uniqueKey = title,
  backgroundColor,
  icon = "",
  noExpandedIcon = false,
  backgroundColorShadow = "",
  completed = false,
  style = {},
  cost = 0,
  onChange = (t) => (a, b) => {},
}: AccordionComponentProps) {
  const isExpanded = useMemo(() => expanded === uniqueKey, [expanded, title]);
  const resultedBackgroundColor = completed
    ? "hsla(222, 18%, 75%, 1)"
    : backgroundColor;

  const resultedBoxShadow = useMemo(() => {
    const boxShadowPosition = "0px 5px 24px";
    if (completed && isExpanded) {
      return `${boxShadowPosition} hsla(222, 18%, 75%, 1)`;
    }
    if (completed && !isExpanded) {
      return `${boxShadowPosition} transparent`;
    }
    if (isExpanded && backgroundColorShadow) {
      return `${boxShadowPosition} ${backgroundColorShadow}`;
    }
    return `${boxShadowPosition} transparent`;
  }, [backgroundColorShadow, isExpanded, completed]);

  return (
    <CustomAccordion
      expanded={isExpanded}
      onChange={onChange(uniqueKey)}
      style={style}
    >
      <AccordionSummary
        style={{
          backgroundColor: resultedBackgroundColor,
          overflow: "hidden",
          transition: "box-shadow 0.3s",
          boxShadow: resultedBoxShadow,
        }}
        className={`${s.summery} ${completed ? s.completed : ""}`}
        aria-controls="panel1a-content"
        id={`panel1a-header-${title}`}
      >
        <div className={s.summeryContent}>
          <div className={s.leftPart}>
            {icon && (
              <div className={s.iconWrapper}>
                <div
                  className={s.iconBackground}
                  style={{
                    color: completed
                      ? "hsla(222, 18%, 75%, 1)"
                      : backgroundColor,
                  }}
                >
                  {icon}
                </div>
                <div className={s.visibleIcon}>{icon}</div>
              </div>
            )}
            <span className={s.title}>{title}</span>
          </div>
          {!!cost && !noExpandedIcon && (
            <div className={s.rightPart}>
              {!!cost && (
                <div className={s.rewardWrapper}>
                  <Reward
                    cost={cost}
                    isCompleted={completed}
                    mainClass={s.reward}
                    aceClass={s.ace}
                    iconClass={s.icon}
                    plusClass={s.plus}
                  />
                </div>
              )}
              {!noExpandedIcon && completed && (
                <div className={s.check}>
                  <span className="icon-check" />
                </div>
              )}
              {!noExpandedIcon && !completed && (
                <span
                  className={`icon-arrowDown ${s.expandIcon} ${
                    isExpanded ? s.expanded : ""
                  }`}
                />
              )}
            </div>
          )}
        </div>
      </AccordionSummary>
      <MuiAccordionDetails style={{ padding: "0px" }}>
        {children}
      </MuiAccordionDetails>
    </CustomAccordion>
  );
}

export default Accordion;
