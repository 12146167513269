/* eslint-disable react/jsx-props-no-spreading */
import React, { useId, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import s from "./MajorSelect.module.scss";

const CustomInput = styled(InputBase)((props) => {
  return {
    "& .MuiSelect-icon": {
      transition: "0.2s",
    },
    "& .MuiInputBase-input": {
      transition: props.theme.transitions.create([
        "border-color",
        "box-shadow",
      ]),
      width: "100%",
      height: "100% !important",
      padding: "0 30px 0 30px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
      border: props?.inputProps?.open
        ? "1px solid hsla(32, 100%, 62%, 1)"
        : "1px solid hsla(220, 19%, 38%, 1)",
      borderRadius: "20px",

      "&:focus": {
        borderColor: "hsla(32, 100%, 62%, 1)",
        borderRadius: "20px",
      },
    },
  };
});

interface SelectItem {
  value: string;
  title: string;
}

interface MajorSelectProps extends SelectProps {
  items: SelectItem[];
}

function MajorSelect({ items, ...props }: MajorSelectProps) {
  const [open, setOpen] = useState<boolean>(false);
  const id = useId();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getIcon = () => {
    return null;
  };

  return (
    <FormControl
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <Select
        {...props}
        inputProps={{
          style: { transition: "0.3s", paddingLeft: "6px" },
          "aria-label": "Without label",
        }}
        className={s.select}
        MenuProps={{
          PaperProps: {
            className: s.menuWrapper,
          },
          MenuListProps: {
            className: s.menu,
          },
        }}
        IconComponent={getIcon}
        input={<CustomInput />}
        id={id}
        renderValue={(selected: any) => {
          if (!selected) {
            return <span>{props.placeholder}</span>;
          }

          return selected;
        }}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {items.map((item) => (
          <MenuItem
            className={s.selectItem}
            value={item.value}
            key={item.title}
          >
            {item.title}
          </MenuItem>
        ))}
      </Select>
      <div className={`${s.icon} ${open ? s.open : ""}`}>
        <span className="icon-arrowDown" />
      </div>
    </FormControl>
  );
}

export default MajorSelect;
