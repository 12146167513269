/* eslint-disable react/jsx-props-no-spreading */
import React, { useId, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import s from "./LanguageSelect.module.scss";

interface SelectItem {
  value: string;
  title: string;
}

interface LanguageSelectProps extends SelectProps {
  items: SelectItem[];
}

const CustomInput = styled(InputBase)((props) => {
  return {
    "& .MuiSelect-icon": {
      transition: "0.2s",
    },
    "& .MuiInputBase-input": {
      width: "100%",
      height: "100% !important",
      display: "flex",
      alignItems: "center",
      padding: "0 !important",
      textTransform: "uppercase",
      justifyContent: "center",
      backgroundColor: "white",
      border: props?.inputProps?.open
        ? "1px solid hsla(32, 100%, 62%, 1)"
        : "1px solid hsla(32, 100%, 62%, 1)",
      borderRadius: "20px",
      transition: "0.3s",

      "&:hover": {
        backgroundColor: "hsla(32, 100%, 62%, 1)",
        color: "white",
      },

      "&:focus": {
        borderColor: "hsla(32, 100%, 62%, 1)",
        borderRadius: "20px",
      },
    },
  };
});

function LanguageSelect({ items, ...props }: LanguageSelectProps) {
  const [open, setOpen] = useState<boolean>(false);
  const id = useId();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getIcon = () => {
    return null;
  };

  return (
    <FormControl
      className={s.wrapper}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      {/* <div className={s.iconBefore}>
        <span className="icon-global" />
      </div> */}
      <Select
        {...props}
        inputProps={{
          style: { transition: "0.3s", paddingLeft: "6px" },
          "aria-label": "Without label",
        }}
        className={s.select}
        MenuProps={{
          PaperProps: {
            className: s.menuWrapper,
          },
          MenuListProps: {
            className: s.menu,
          },
        }}
        IconComponent={getIcon}
        input={<CustomInput />}
        id={id}
        renderValue={(selected: any) => {
          return (
            <div className={s.valueContainer}>
              <div className={s.iconBefore}>
                <span className="icon-global" />
              </div>
              {selected}
              <div className={`${s.iconAfter} ${open ? s.open : ""}`}>
                <span className="icon-triangle" />
              </div>
            </div>
          );
        }}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {items.map((item) => {
          if (item?.value === props?.value) {
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return null;
          }
          return (
            <MenuItem
              className={s.selectItem}
              value={item.value}
              key={item.title}
            >
              {item.title}
            </MenuItem>
          );
        })}
      </Select>
      {/* <div className={`${s.icon} ${open ? s.open : ""}`}>
        <span className="icon-triangle" />
      </div> */}
    </FormControl>
  );
}

export default LanguageSelect;
