import React from "react";
import logoImg from "images/logo.svg";
import Loader from "react-loaders";

import s from "./LoadingPage.module.scss";

function LoadingPage() {
  return (
    <div className={s.loadingPage}>
      <div className={s.content}>
        <div className={s.loaderWrapper}>
          <Loader type="line-scale-pulse-out-rapid" active />
        </div>
        <div className={s.logoWrapper}>
          <img src={logoImg} alt="" className={s.logo} />
        </div>
      </div>
    </div>
  );
}

export default LoadingPage;
