import React from "react";

import s from "./Button.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
}

function Button({ children = "", className = "", ...props }: Props) {
  return (
    <button type="button" className={`${s.button} ${className}`} {...props}>
      {children}
    </button>
  );
}

export default Button;
