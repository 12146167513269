import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ScrollArea from "react-scrollbar";

import useGetUser from "hooks/query/useGetUser";

import LootBoxModal from "components/Modal/LootBoxModal";
import ProgressBar from "./ProgressBar";

import GiftBlock from "./GiftBlock";

import s from "./EarnedPanel.module.scss";

function EarnedPanel() {
  const { t } = useTranslation();
  const { data: user } = useGetUser();

  const [openModal, setModalOpen] = useState(false);

  const close = () => {
    setModalOpen(false);
  };

  const openLootBox = () => {
    setModalOpen(true);
  };

  const maxBalance = 7;

  const earnedValue = useMemo(() => user?.completed_daily_tasks || 0, [user]);

  const isGetGiftAvailable = useMemo(() => earnedValue >= 7, [earnedValue]);

  return (
    <>
      <div className={s.earnedContainer}>
        <div className={s.content}>
          <div className={s.topPart}>
            <div className={s.title}>{t("missions.earned")}</div>
            <div className={s.currentBalance}>
              <span className={`icon-balanceWrapped ${s.balanceIcon}`} />
              <span>{user?.balance} ACE</span>
            </div>
          </div>
          <div className={s.progressBarWrapper}>
            <ProgressBar maxBalance={maxBalance} dayPassed={earnedValue} />
          </div>
          <ScrollArea
            className={s.scrollArea}
            contentClassName={s.scrollContent}
            horizontalContainerStyle={{
              height: "6px",
              transform: "scale(0.95)",
            }}
            horizontalScrollbarStyle={{
              height: "6px",
            }}
          >
            <ProgressBar maxBalance={maxBalance} dayPassed={earnedValue} />
            <div className={s.giftWrapperMobile}>
              <GiftBlock
                openLootBox={openLootBox}
                isAvailable={isGetGiftAvailable}
              />
            </div>
          </ScrollArea>
          <div className={s.additionalText}>{t("missions.bonusText")}</div>
        </div>
        <div className={s.giftWrapper}>
          <GiftBlock
            openLootBox={openLootBox}
            isAvailable={isGetGiftAvailable}
          />
        </div>
      </div>
      <LootBoxModal open={openModal} close={close} />
    </>
  );
}

export default EarnedPanel;
