/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import s from "./MinorSelect.module.scss";

const CustomInput = styled(InputBase)(({ theme }) => ({
  "& .MuiSelect-icon": {
    transition: "0.2s",
  },
  "& .MuiInputBase-input": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    width: "100%",
    height: "100%",
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center",
  },
}));

interface SelectItem {
  value: string;
  title: string;
}

interface MinorSelectProps extends SelectProps {
  items: SelectItem[];
}

function MinorSelect({ items, ...props }: MinorSelectProps) {
  return (
    <FormControl style={{ width: "100%" }}>
      <Select
        {...props}
        inputProps={{
          "aria-label": "Without label",
          style: { transition: "0.3s", paddingLeft: "6px" },
        }}
        className={s.select}
        input={<CustomInput />}
      >
        {items.map((item) => (
          <MenuItem
            className={s.selectItem}
            value={item.value}
            key={item.title}
          >
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MinorSelect;
