import { useMutation, useQueryClient } from "@tanstack/react-query";
import { changeWallet } from "api/user";
import getFirebaseIdToken from "utils/getFirebaseIdToken";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";
import { useTranslation } from "react-i18next";

interface Payload {
  wallet_address: string;
}

const useChangeWallet = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const showSnackBar = useShowSnackBar();

  return useMutation<void, Error, Payload>(
    ["change-wallet"],
    async ({ wallet_address }) => {
      const token = await getFirebaseIdToken();
      if (token && wallet_address) {
        return changeWallet(token, { wallet_address });
      }

      if (wallet_address == null)
        return Promise.reject(new Error("Invalid wallet address"));
      return Promise.reject(new Error("Invalid Firebase ID Token"));
    },
    {
      onSuccess: () => {
        showSnackBar({
          title: t("notification.walletChanged.title"),
          text: t("notification.walletChanged.text"),
        });
        return queryClient.invalidateQueries({ queryKey: ["user"] });
      },
      onError: () => {
        showSnackBar({
          title: t("notification.commonError.title"),
          text: t("notification.commonError.text"),
          variant: "error",
        });
      },
    },
  );
};

export default useChangeWallet;
