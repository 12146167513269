import { useMutation, useQueryClient } from "@tanstack/react-query";
import { changeRoleAndPrivate } from "api/user";
import getFirebaseIdToken from "utils/getFirebaseIdToken";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";
import { useTranslation } from "react-i18next";

interface Payload {
  role: string;
  private_info?: string;
}

const useChangeExtra = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const showSnackBar = useShowSnackBar();

  return useMutation<void, Error, Payload>(
    ["change-extra"],
    async ({ role, private_info }) => {
      const token = await getFirebaseIdToken();

      if (token && role) {
        return changeRoleAndPrivate(token, { role, private_info });
      }

      if (role == null || private_info == null)
        return Promise.reject(new Error("Invalid private_info of role"));

      if (!token) return Promise.reject(new Error("Invalid Firebase ID Token"));

      return Promise.reject(new Error("Something went wrong"));
    },
    {
      onSuccess: () => {
        showSnackBar({
          title: t("notification.extraChanged.title"),
          text: t("notification.extraChanged.text"),
        });
        return queryClient.invalidateQueries({ queryKey: ["user"] });
      },
      onError: () => {
        showSnackBar({
          title: t("notification.commonError.title"),
          text: t("notification.commonError.text"),
          variant: "error",
        });
      },
    },
  );
};

export default useChangeExtra;
