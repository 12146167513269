import { useMutation } from "@tanstack/react-query";
import {
  signInWithPopup,
  GoogleAuthProvider,
  UserCredential,
} from "firebase/auth";
import { auth } from "utils/firebase";

interface Params {
  onSuccess?: (data: UserCredential) => void;
}

const DEFAULT_PARAMS: Params = {
  onSuccess: undefined,
};

export interface GooglePayload {
  provider: "google";
}

export type Payload = GooglePayload;

const useLogIn = ({ onSuccess }: Params = DEFAULT_PARAMS) => {
  return useMutation<UserCredential, Error, void>(
    () => {
      const provider = new GoogleAuthProvider();
      return signInWithPopup(auth, provider);
    },
    {
      onSuccess,
    },
  );
};

export default useLogIn;
