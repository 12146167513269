import React, { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Loader from "react-loaders";
import useInitializeAccount from "hooks/query/useInitializeAccount";
import useSendEmail from "hooks/query/useSendEmail";
import useSendCode from "hooks/query/useSendCode";
import useCreateUser from "hooks/query/useCreateUser";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "utils/firebase";

import Button from "components/Button";
import Welcome from "components/Welcome";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";

import s from "./AuthorizationPage.module.scss";

function AuthorizationPage() {
  const { t } = useTranslation();
  const showSnackBar = useShowSnackBar();
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [isEmailSended, setIsEmailSended] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const hash = useMemo(
    () => searchParams.get("r") || undefined,
    [searchParams],
  );

  const { mutate: initializeAccount } = useInitializeAccount({ hash });
  const { mutateAsync: sendEmail, isLoading: emailLoading } = useSendEmail();
  const { mutateAsync: sendCode, isLoading: codeLoading } = useSendCode();
  const { mutateAsync: signUp } = useCreateUser();

  const handleInitializeAccountViaGoogle = useCallback(() => {
    initializeAccount();
  }, [initializeAccount]);

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const inputCodeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setCode(e.target.value);
  };

  const cancelEmail = () => {
    setCode("");
    setIsEmailSended(false);
  };

  const formSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isEmailSended) {
      const { token } = await sendCode({ email, code });
      await signInWithCustomToken(auth, token);
      await signUp({ hash });
      setIsEmailSended(false);
    } else {
      await sendEmail({ email });
      showSnackBar({
        title: t("notification.checkEmail.title"),
        text: `${t("notification.checkEmail.text")} ${email}!`,
      });
      setIsEmailSended(true);
    }
  };

  return (
    <Welcome formSubmitHandler={formSubmitHandler}>
      <div className={s.inputWrapper}>
        {isEmailSended ? (
          <>
            <input
              type="text"
              className={s.input}
              placeholder="Enter code"
              value={code}
              onChange={inputCodeChangeHandler}
            />
            <button
              type="button"
              onClick={cancelEmail}
              className={s.iconWrapper}
            >
              <div className="icon-remove" />
            </button>
          </>
        ) : (
          <input
            type="text"
            className={s.input}
            placeholder={String(t("authorization.placeholder"))}
            value={email}
            onChange={inputChangeHandler}
          />
        )}
      </div>
      <div className={s.submitWrapper}>
        <Button type="submit" disabled={emailLoading || codeLoading}>
          {emailLoading || codeLoading ? (
            <div className={s.loaderWrapper}>
              <Loader
                type="line-scale-pulse-out-rapid"
                active
                innerClassName={s.loader}
              />
            </div>
          ) : (
            t("authorization.signIn")
          )}
        </Button>
      </div>
      <div className={s.separator}>
        <div className={s.line} />
        <span>{t("authorization.or")}</span>
        <div className={s.line} />
      </div>
      <div className={s.googleButtonWrapper}>
        <button
          type="button"
          className={s.googleButton}
          onClick={handleInitializeAccountViaGoogle}
        >
          <span className="icon-google">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
          </span>
          {t("authorization.google")}
        </button>
      </div>
    </Welcome>
  );
}

export default AuthorizationPage;
