import { useQuery } from "@tanstack/react-query";
import { getInventory } from "api/inventory";
import { InventoryItem } from "types/inventory";
import getFirebaseIdToken from "utils/getFirebaseIdToken";

const useGetInventory = () => {
  return useQuery<InventoryItem[] | null, Error>(["inventory"], async () => {
    const token = await getFirebaseIdToken();
    if (token) {
      return getInventory(token);
    }
    return Promise.resolve(null);
  });
};

export default useGetInventory;
