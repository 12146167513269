import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { checkTask } from "api/missions";
import { Codename } from "types/task";
import delay from "utils/delay";
import getFirebaseIdToken from "utils/getFirebaseIdToken";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";
import { useTranslation } from "react-i18next";

interface Payload {
  codename: Codename;
  taskName: string;
  worth: number;
  result?: string;
  file?: File;
  delayTime?: number;
}

const useCheckTask = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const showSnackBar = useShowSnackBar();

  return useMutation<void, Error, Payload>(
    ["check-task"],
    async ({ codename, result, file }) => {
      const token = await getFirebaseIdToken();
      if (token && codename) {
        return checkTask(token, { codename, result, file });
      }

      if (codename == null)
        return Promise.reject(new Error("Invalid codename"));
      return Promise.reject(new Error("Invalid Firebase ID Token"));
    },
    {
      onSuccess: async (_, { delayTime, taskName, worth }) => {
        if (delayTime) await delay(delayTime);
        showSnackBar({
          title: (
            <>
              {t("notification.earnedAce.title")}{" "}
              <span className="orangeBalance">
                <span className="icon-balanceWrapped" />
              </span>
              {worth} ace
            </>
          ),
          text: `${t("notification.earnedAce.text")} 
          ${taskName}`,
        });
        return Promise.all([
          queryClient.invalidateQueries({ queryKey: ["user"] }),
          queryClient.invalidateQueries({ queryKey: ["tasks"] }),
        ]);
      },
      onError: (error) => {
        showSnackBar({
          title: t("notification.commonError.title"),
          text: `${error.message}`,
          variant: "error",
        });
      },
    },
  );
};

export default useCheckTask;
