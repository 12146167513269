/* eslint-disable import/prefer-default-export */
import { MissionData } from "types/task";

interface Data {
  [str: string]: MissionData;
}

export const missionsData: Data = {
  twitter_write_post_art: {
    logo: "twitter",
    title: "TRACE is…",
    description: `Write a post about TRACE on Twitter – What does the project mean to you? Tag our account and paste the link to the post:`,
    link: ``,
    responseForm: "link",
    cost: 4,
  },
  instagram_create_post: {
    logo: "instagram",
    title: "TRACE is…",
    description: `Write a post about TRACE on Instagram – What does the project mean to you? Tag our account and paste the link to the post:`,
    link: ``,
    responseForm: "link",
    cost: 4,
  },
  linkedln_create_post: {
    // logo: "lindkedln",
    logo: "",
    title: "TRACE is…",
    description: `Write a post about TRACE on LinkedIn – What does the project mean to you? Tag our account and paste the link to the post:`,
    link: ``,
    responseForm: "link",
    cost: 4,
  },
  telegram_create_post: {
    logo: "telegram",
    title: "TRACE is…",
    description: `Write a post about TRACE in the Telegram chat  – What does the project mean to you? Paste link to post:`,
    link: ``,
    responseForm: "link",
    cost: 4,
  },
  instagram_stories_post: {
    logo: "instagram",
    title: "Post Stories!",
    description: `Post TRACE art to your Instagram stories (link) and tag our account. Paste a link with the completed task:`,
    link: `https://drive.google.com/drive/folders/1zLbIERioWhYXhDezISl-RAnxs3igBX6-?usp=share_link`,
    responseForm: "image",
    cost: 3,
  },
  instagram_stories_video: {
    logo: "instagram",
    title: "Post Stories!",
    description: `Post the TRACE video to Instagram stories (link) and tag our account. Paste a link with the completed task:`,
    link: ` https://drive.google.com/drive/folders/1zLbIERioWhYXhDezISl-RAnxs3igBX6-?usp=share_link`,
    responseForm: "image",
    cost: 3,
  },
  instagram_repost_stories: {
    logo: "instagram",
    title: "Repost Stories!",
    description: `Repost to your Instagram story. Paste a link with the completed task:`,
    link: ``,
    responseForm: "image",
    cost: 3,
  },
  instagram_share_stories: {
    logo: "instagram",
    title: "Share Stories!",
    description: `Share TRACE on Instagram Stories in text or voice format. Tag our account and paste a link with the completed task:`,
    link: ``,
    responseForm: "image",
    cost: 3,
  },
  instagram_follow: {
    logo: "instagram",
    title: "Follow us on Instagram!",
    description: `Follow Instagram TRACE.`,
    link: ``,
    responseForm: "link",
    cost: 2,
  },
  telegram_follow: {
    logo: "telegram",
    title: "Follow us on Telegram!",
    description: `Follow Telegram TRACE.`,
    link: ``,
    responseForm: "link",
    cost: 2,
  },
  tiktok_follow: {
    // logo: "tiktok",
    logo: "",
    title: "Follow us on TikTok!",
    description: `Follow TRACE TikTok.`,
    link: ``,
    responseForm: "link",
    cost: 2,
  },
  youtube_follow: {
    logo: "youTube",
    title: "Follow us on YouTube!",
    description: `Follow Instagram TRACE.`,
    link: ``,
    responseForm: "link",
    cost: 2,
  },
  twitter_follow: {
    logo: "twitter",
    title: "Follow us on Twitter!",
    description: `Follow Instagram TRACE.`,
    link: ``,
    responseForm: "link",
    cost: 2,
  },
  linkedln_follow: {
    // logo: "lindkedln",
    logo: "",
    title: "Follow us on Linkedln!",
    description: `Follow Instagram TRACE.`,
    link: ``,
    responseForm: "link",
    cost: 2,
  },
  twitter_create_post_art: {
    logo: "twitter",
    title: "Repost in Twitter! ",
    description: `Post on Twitter with our art, located at this link. Paste a link with the completed task:`,
    link: `https://drive.google.com/drive/folders/167OpcplSX6YSUftiVaZhEvPC3U8ynESD?usp=sharing`,
    responseForm: "link",
    cost: 2,
  },
  lindkedln_create_post_art: {
    // logo: "lindkedln",
    logo: "",
    title: "Repost in LinkedIn!",
    description: `Post on Lindkedln with our art, located at this link. Paste a link with the completed task:`,
    link: `https://drive.google.com/drive/folders/167OpcplSX6YSUftiVaZhEvPC3U8ynESD?usp=sharing`,
    responseForm: "link",
    cost: 2,
  },
  twitter_create_video_review_metaverse: {
    logo: "twitter",
    title: "1 minute about TRACE ",
    description: `Make a video review of TRACE: Metaverse, App, Drive-and-Earn (minimum 1 minute). Post on Twitter with our account tag and paste a link with the completed task:`,
    link: ``,
    responseForm: "link",
    cost: 6,
  },
  instagram_create_video_review_metaverse: {
    logo: "instagram",
    title: "2 minute about TRACE",
    description: `Make a video review of TRACE: Metaverse, App, Drive-and-Earn (minimum 1 minute). Post on Instagram with our account tag and paste a link with the completed task:`,
    link: ``,
    responseForm: "link",
    cost: 6,
  },
  tiktok_create_video_review_metaverse: {
    // logo: "tiktok",
    logo: "",
    title: "3 minute about TRACE",
    description: `Make a video review of TRACE: Metaverse, App, Drive-and-Earn (minimum 1 minute). Post to TikTok with our account tag and paste a link with the completed task:`,
    link: ``,
    responseForm: "link",
    cost: 6,
  },
  twitter_create_video_review_gem: {
    logo: "twitter",
    title: "4 minute about TRACE",
    description: `Make a video review of the TRACE gem platform (minimum 1 minute). Post on Twitter with our account tag and paste a link with the completed task:`,
    link: ``,
    responseForm: "link",
    cost: 6,
  },
  instagram_create_video_review_gem: {
    logo: "instagram",
    title: "5 minute about TRACE",
    description: `Make a video review of the TRACE gem platform (minimum 1 minute). Post on Instagram with our account tag and paste a link with the completed task:`,
    link: ``,
    responseForm: "link",
    cost: 6,
  },
  tiktok_create_video_review_gem: {
    logo: "tiktok",
    title: "6 minute about TRACE",
    description: `Make a video review of the TRACE gem platform (minimum 1 minute). Post to TikTok with our account tag and paste a link with the completed task:`,
    link: ``,
    responseForm: "link",
    cost: 6,
  },
  twitter_follow_bogdan_evtushenko: {
    logo: "twitter",
    title: "Meet CEO TRACE! ",
    description: `Follow TRACE CEO Bogdan Evtushenko on Twitter. Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "link",
    cost: 2,
  },
  telegram_follow_bogdan_evtushenko: {
    logo: "telegram",
    title: "Meet CEO TRACE! ",
    description: `Follow TRACE CEO Bogdan Evtushenko on Telegram. Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "link",
    cost: 2,
  },
  youtube_follow_bogdan_evtushenko: {
    logo: "youTube",
    title: "Meet CEO TRACE! ",
    description: `Follow TRACE CEO Bogdan Evtushenko on YouTube. Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "link",
    cost: 2,
  },
  instagram_follow_bogdan_evtushenko: {
    logo: "instagram",
    title: "Meet CEO TRACE! ",
    description: `Follow TRACE CEO Bogdan Evtushenko on Instagram. Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "link",
    cost: 2,
  },
  discord_talk: {
    logo: "discord",
    title: "Talk to TRACE ",
    description: `Take part in one of the voice chats on Discord! Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 2,
  },
  discord_role_challengers: {
    logo: "discord",
    title: "Your role in TRACE ",
    description: `Get the "Challengers" role on Discord! Check the server for information on how to do it. Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 2,
  },
  discord_role_giveaways: {
    logo: "discord",
    title: "Your role in TRACE ",
    description: `Get the "Giveaways" role on Discord! Check the server for information on how to do this. Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 5,
  },
  discord_role_raiders: {
    logo: "discord",
    title: "Your role in TRACE ",
    description: `Get the "Raiders" role on Discord! Check the server for information on how to do this. Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 5,
  },
  discord_role_tracelist: {
    logo: "discord",
    title: "Your role in TRACE ",
    description: `Get the "Tracelist" role on Discord! Check the server for information on how to do this. Paste a link with the completed task:`,
    link: ``,
    responseForm: "image",
    cost: 5,
  },
  twitter_set_image_car_profile: {
    logo: "twitter",
    title: "New avatar",
    description: `Put an image of NFT Car TRACE on your Twitter profile. Here are the avatar options (link). Paste a screenshot link with the completed task on Google Drive:`,
    link: `https://drive.google.com/drive/folders/1BnZ7Se6dbzs5j0hxhEo0RrSBx-uIKVB8?usp=sharing`,
    responseForm: "image",
    cost: 3,
  },
  discord_set_image_car_profile: {
    logo: "discord",
    title: "New avatar",
    description: `Put an image of NFT Car TRACE on your Discord profile. Here are the avatar options (link). Paste a screenshot link with the completed task on Google Drive:`,
    link: `https://drive.google.com/drive/folders/1BnZ7Se6dbzs5j0hxhEo0RrSBx-uIKVB8?usp=sharing`,
    responseForm: "image",
    cost: 3,
  },
  instagram_set_image_car_profile: {
    logo: "instagram",
    title: "New avatar",
    description: `Put an image of NFT Car TRACE on your Instagram profile. Here are the avatar options (link). Paste a screenshot link with the completed task on Google Drive:`,
    link: `https://drive.google.com/drive/folders/1BnZ7Se6dbzs5j0hxhEo0RrSBx-uIKVB8?usp=sharing`,
    responseForm: "image",
    cost: 3,
  },
  telegram_set_image_car_profile: {
    logo: "telegram",
    title: "New avatar",
    description: `Put an image of NFT Car TRACE on your Telegram profile. Here are the avatar options (link). Paste a screenshot link with the completed task on Google Drive:`,
    link: `https://drive.google.com/drive/folders/1BnZ7Se6dbzs5j0hxhEo0RrSBx-uIKVB8?usp=sharing`,
    responseForm: "image",
    cost: 3,
  },
  tiktok_set_image_car_profile: {
    // logo: "tiktok",
    logo: "",
    title: "New avatar",
    description: `Put an image of NFT Car TRACE on your Tiktok profile. Here are the avatar options (link). Paste a screenshot link with the completed task on Google Drive:`,
    link: `https://drive.google.com/drive/folders/1BnZ7Se6dbzs5j0hxhEo0RrSBx-uIKVB8?usp=sharing`,
    responseForm: "image",
    cost: 3,
  },
  twitter_set_description_metaverse: {
    logo: "twitter",
    title: "Add to description",
    description: `Write in the description of your Twitter account "I play and earn with TRACE Metaverse @trace_meta!" Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 3,
  },
  instagram_set_description_metaverse: {
    logo: "instagram",
    title: "Add to description",
    description: `Write in the description of your Instagram account "I play and earn with TRACE Metaverse @trace.meta!" Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 3,
  },
  discord_set_description_metaverse: {
    logo: "discord",
    title: "Add to description",
    description: `Write in the description of your Discord account "I play and earn with TRACE Metaverse!" Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 3,
  },
  telegram_set_description_metaverse: {
    logo: "telegram",
    title: "Add to description",
    description: `Write in the description of your Telegram account "I play and earn with TRACE Metaverse @trace_global!" Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 3,
  },
  tiktok_set_description_metaverse: {
    // logo: "tiktok",
    logo: "",
    title: "Add to description",
    description: `Write in the description of your TikTok account "I play and earn with TRACE Metaverse @trace_meta!" Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 3,
  },
  twitter_raid: {
    logo: "twitter",
    title: "Raid in Twitter!",
    description: `Raid: 5 likes and 5 retweets of the latest Twitter posts. Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 2,
  },
  instagram_raid: {
    logo: "instagram",
    title: "Raid in Instagram!",
    description: `Raid: 5 likes and 5 reposts of the latest posts on Instagram. Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 2,
  },
  tiktok_raid: {
    // logo: "tiktok",
    logo: "",
    title: "Raid in TikTok!",
    description: `Raid: 10 likes and 5 duets with the latest TikTok videos. Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 4,
  },
  youtube_raid: {
    logo: "youTube",
    title: "Raid in YouTube!",
    description: `Raid: 10 likes and write 5 comments under the latest YouTube videos. Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 4,
  },
  medium_raid: {
    logo: "medium",
    title: "Raid in Medium!",
    description: `Raid: Like and comment on all of our Medium articles. Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 3,
  },
  trace_rss: {
    logo: "",
    title: "Stay up to date",
    description: `Subscribe to the TRACE newsletter! Paste a screenshot link with the completed task on Google Drive:`,
    link: ``,
    responseForm: "image",
    cost: 3,
  },
  simple_how_old_are_you: {
    logo: "",
    title: "Get ACE for the answer",
    description: "How old are you?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_where_do_you_live: {
    logo: "",
    title: "Get ACE for the answer",
    description: "Where do you live? (Country and city)",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_what_lang_know: {
    logo: "",
    title: "Get ACE for the answer",
    description: "What languages do you know?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_what_transport_use_daily: {
    logo: "",
    title: "Get ACE for the answer",
    description: "What type of transport do you use daily?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_what_transport_have: {
    logo: "",
    title: "Get ACE for the answer",
    description:
      "What kind of transport do you have? (car/bicycle/scooter etc)",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_have_using_cryptocurrency: {
    logo: "",
    title: "Get ACE for the answer",
    description: "How long have you been using cryptocurrency?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_crypto_wallets_exchanges: {
    logo: "",
    title: "Get ACE for the answer",
    description: "What crypto wallets and exchanges do you use?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_Issue_what_tokens: {
    logo: "",
    title: "Get ACE for the answer",
    description: "Issue of what tokens are you waiting for?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_Issue_nft_collections: {
    logo: "",
    title: "Get ACE for the answer",
    description: "Issue of what NFT-collections are you waiting for?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_what_nft_you: {
    logo: "",
    title: "Get ACE for the answer",
    description: "What NFT collections do you have?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_what_play_and_earn_forward: {
    logo: "",
    title: "Get ACE for the answer",
    description: "What Play-and-earn projects are you looking forward to?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_which_play_and_earn_holder: {
    logo: "",
    title: "Get ACE for the answer",
    description: "Which Play-and-earn projects are you a holder of?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_crypto_projects_follow: {
    logo: "",
    title: "Get ACE for the answer",
    description: "What crypto projects do you follow?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_cryptocurrencies_tokens_use_most: {
    logo: "",
    title: "Get ACE for the answer",
    description: "What cryptocurrencies and tokens do you use most often?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_what_games: {
    logo: "",
    title: "Get ACE for the answer",
    description: "What games you are playing?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_what_games_waitng: {
    logo: "",
    title: "Get ACE for the answer",
    description: "What games are you waiting for?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_metaverses_forward: {
    logo: "",
    title: "Get ACE for the answer",
    description: "Which Metaverses are you looking forward to?",
    link: "",
    responseForm: "short_text",
    cost: 1,
  },
  simple_what_top_cryptocurrencies: {
    logo: "",
    title: "Share your opinion!",
    description: "What's your TOP 5 cryptocurrencies?",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_name_best_wallets: {
    logo: "",
    title: "Share your opinion!",
    description: "Name the best wallets",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_name_top_cryptocurrencies: {
    logo: "",
    title: "Share your opinion!",
    description: "Name the TOP 5 cryptocurrencies",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_what_top_exchanges: {
    logo: "",
    title: "Share your opinion!",
    description: "What's your TOP 5 exchanges?",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_what_blockchains_promising: {
    logo: "",
    title: "Share your opinion!",
    description:
      "Which blockchains do you consider the most promising in 2023?",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_why_polygon_promising: {
    logo: "",
    title: "Share your opinion!",
    description: "Why do you think the Polygon blockchain is promising?",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_what_nft_polygon_promising: {
    logo: "",
    title: "Share your opinion!",
    description:
      "What NFT collections on the Polygon blockchain do you consider promising?",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_what_criteria_good_nft: {
    logo: "",
    title: "Share your opinion!",
    description: "What are 5 criteria for a good NFT collection?",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_what_top_nft: {
    logo: "",
    title: "Share your opinion!",
    description: "What's your TOP 5 NFT-collections?",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_best_play_and_earn: {
    logo: "",
    title: "Share your opinion!",
    description: "Name the best Play-and-earn projects",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_why_ar_and_vr: {
    logo: "",
    title: "Share your opinion!",
    description: "What do you like more: AR or VR? Why?",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_why_ar_trand: {
    logo: "",
    title: "Share your opinion!",
    description:
      "Why do you think augmented reality will become a trend in 2023?",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_name_games_ar: {
    logo: "",
    title: "Share your opinion!",
    description: "Name 5 games/apps with augmented reality technologies",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_appealing_concept_augmented_games: {
    logo: "",
    title: "Share your opinion!",
    description:
      "What do you find appealing in the concept of augmented games (like Pokémon GO)?",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_name_top_games_pc: {
    logo: "",
    title: "Share your opinion!",
    description: "Name the TOP 5 PC games ",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_name_top_games_mobile: {
    logo: "",
    title: "Share your opinion!",
    description: "Name the TOP 5 mobile games",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_name_top_metaverses: {
    logo: "",
    title: "Share your opinion!",
    description: "Name the TOP 5 Metaverses",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_name_criteria_good_metaverse: {
    logo: "",
    title: "Share your opinion!",
    description: "Name 5 criteria for a good Metaverse",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_think_creates_barrier_metaverse: {
    logo: "",
    title: "Share your opinion!",
    description:
      "What do you think creates a barrier between users and the Metaverse (what creates inconvenience)?",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_ideas_web2_to_web3: {
    logo: "",
    title: "Share your opinion!",
    description: `Write 5 ideas on "How to bridge from Web2 to Web3"`,
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_write_promotion_ideas_mobile_game: {
    logo: "",
    title: "Share your opinion!",
    description: "Write 5 promotion ideas for a mobile game",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_write_promotion_ideas_metaverse: {
    logo: "",
    title: "Share your opinion!",
    description: "Write 5 promotional ideas for the Metaverse",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_write_promotion_ideas_nft: {
    logo: "",
    title: "Share your opinion!",
    description: "Write 5 promotion ideas for NFT collection",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_write_promotion_ideas_token: {
    logo: "",
    title: "Share your opinion!",
    description: "Write 5 token promotion ideas",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  simple_attach_links_top_gem_platform: {
    logo: "",
    title: "Share your opinion!",
    description: "Attach links to top 5 gem platforms you know",
    link: "",
    responseForm: "long_text",
    cost: 2,
  },
  photo_logotype_walking: {
    logo: "",
    title: "TRACE photo from a walk",
    description: `Take a photo with the TRACE logo displayed on your smartphone while walking! Paste a link to the photo on Google Drive:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  socials_photo_logotype_walking: {
    logo: "",
    title: "TRACE photo from a walk",
    description: `Post on your social networks a photo with the TRACE logo on your smartphone while walking. Tag us: Twitter, Discord, Instagram, YouTube. Paste the link to the post:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  photo_logotype_car: {
    logo: "",
    title: "TRACE photo in a car",
    description: `Take a photo with the TRACE logo displayed on your smartphone in the car! Paste a link to the photo on Google Drive:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  socials_photo_logotype_car: {
    logo: "",
    title: "TRACE photo in a car",
    description: `Post on your social networks a photo with the TRACE logo on your smartphone in the car. Tag us: Twitter, Discord, Instagram, YouTube. Paste the link to the post:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  video_logotype_walking: {
    logo: "",
    title: "TRACE video from a walk",
    description: `Make a video with the TRACE logo displayed on your smartphone while walking! Paste a link to the video on Google Drive:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  socials_video_logotype_walking: {
    logo: "",
    title: "TRACE video from a walk",
    description: `Post on your social networks a video with the TRACE logo on your smartphone while walking. Tag us: Twitter, Discord, Instagram, YouTube. Paste the link to the post:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  video_logotype_car: {
    logo: "",
    title: "TRACE video in a car",
    description: `Make a video with the TRACE logo displayed on your smartphone in the car! Paste a link to the video on Google Drive:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  socials_video_logotype_car: {
    logo: "",
    title: "TRACE video in a car",
    description: `Post on your social networks a photo with the TRACE logo on your smartphone in the car. Tag us: Twitter, Discord, Instagram, YouTube. Paste the link to the post:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  photo_logotype_sports: {
    logo: "",
    title: "Sports TRACE photo",
    description: `Make a photo of yourself on "sports" vehicles (bike, roller skates, scooter, skateboard, skis, skates, snowboard, etc. with the TRACE logo displayed on your smartphone! Paste a link to the photo on Google Drive:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  socials_photo_logotype_sports: {
    logo: "",
    title: "Sports TRACE photo",
    description: `Make photos of yourself on "sports" vehicles (bicycle, roller skates, scooter, skateboard, skis, skates, snowboard, etc.) with the TRACE logo on your smartphone. Tag us: Twitter, Discord, Instagram, YouTube. Paste the link to the post:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  photo_logotype_landmark: {
    logo: "",
    title: "TRACE photo with landmark",
    description: `Take a photo with the TRACE logo on your smartphone in front of a landmark! Paste a link to the photo on Google Drive:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  socials_photo_logotype_landmark: {
    logo: "",
    title: "TRACE photo with landmark",
    description: `Post on your social networks a photo with the TRACE logo on your smartphone in front of a landmark. Tag us: Twitter, Discord, Instagram, YouTube. Paste the link to the post:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  video_logotype_landmark: {
    logo: "",
    title: "TRACE video with landmark",
    description: `Make a video with the TRACE logo on your smartphone in front of a landmark! Paste a link to the video on Google Drive:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  socials_video_logotype_landmark: {
    logo: "",
    title: "TRACE video with landmark",
    description: `Post on your social networks a video with the TRACE logo on your smartphone in front of a landmark. Tag us: Twitter, Discord, Instagram, YouTube. Paste the link to the post:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  video_logotype_sports: {
    logo: "",
    title: "Sports TRACE video",
    description: `Make a video on "sports" vehicles (bike, roller skates, scooter, skateboard, skis, skates, snowboard, etc.) with the TRACE logo displayed on your smartphone! Paste a link to the video on Google Drive:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  socials_video_logotype_sports: {
    logo: "",
    title: "Sports TRACE video",
    description: `Post videos on your social networks on "sports" vehicles (bicycle, roller skates, scooter, skateboard, skis, skates, snowboard, etc.) with the TRACE logo on your smartphone. Tag us: Twitter, Discord, Instagram, YouTube. Paste the link to the post:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  photo_logotype_vehicles: {
    logo: "",
    title: "TRACE-photo on motor vehicle",
    description: `Take photos on vehicles such as motorcycles, quad bikes, jet skis, snowmobiles, go-karts, and more. with the TRACE logo displayed on your smartphone! Paste a link to the photo on Google Drive:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  socials_photo_logotype_vehicles: {
    logo: "",
    title: "TRACE-photo on motor vehicle",
    description: `Post photos on your social networks on motorcycle, ATV, jet ski, snowmobile, go-cart, etc. with the TRACE logo on your smartphone. Tag us: Twitter, Discord, Instagram, YouTube. Paste the link to the post:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  video_logotype_vehicles: {
    logo: "",
    title: "TRACE-photo on motor vehicle",
    description: `Make videos on vehicles such as motorcycle, quad bike, jet ski, snowmobile, kart, etc. with the TRACE logo displayed on your smartphone! Paste a link to the video on Google Drive:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  socials_video_logotype_vehicles: {
    logo: "",
    title: "TRACE-photo on motor vehicle",
    description: `Post videos on your social networks on motorcycle, ATV, jet ski, snowmobile, go-kart, etc. with the TRACE logo on your smartphone. Tag us: Twitter, Discord, Instagram, YouTube. Paste the link to the post:`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  screenshot_complete_10km: {
    logo: "",
    title: "10km with TRACE",
    description: `Complete 10km on a bike/scooter/skateboard/rollerblade, etc. Take a screenshot from the tracker app and post it to your social networks by tagging us Twitter, Discord, Instagram, YouTube. Paste the link to the post here.`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  screenshot_complete_5km: {
    logo: "",
    title: "5km with TRACE",
    description: `Complete 5km on a bike/scooter/skateboard/rollerblade, etc. Take a screenshot from the tracker app and post it to your social networks by tagging us Twitter, Discord, Instagram, YouTube. Paste the link to the post here.`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  screenshot_complete_1km: {
    logo: "",
    title: "1km with TRACE",
    description: `Complete 1km on a bike/scooter/skateboard/rollerblade, etc. Take a screenshot from the tracker app and post it to your social networks by tagging us Twitter, Discord, Instagram, YouTube. Paste the link to the post here.`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 4,
  },
  screenshot_walk_complete_5km: {
    logo: "",
    title: "5km with TRACE",
    description: `Walk/run 5km. Take a screenshot from the tracker app and post it to your social networks by tagging us Twitter, Discord, Instagram, YouTube. Paste the link to the post here.`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  screenshot_walk_complete_3km: {
    logo: "",
    title: "3km with TRACE",
    description: `Walk/run 3km. Take a screenshot from the tracker app and post it to your social networks by tagging us Twitter, Discord, Instagram, YouTube. Paste the link to the post here.`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  screenshot_walk_complete_1km: {
    logo: "",
    title: "1km with TRACE",
    description: `Walk/run 1km. Take a screenshot from the tracker app and post it to your social networks by tagging us Twitter, Discord, Instagram, YouTube. Paste the link to the post here.`,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 4,
  },
  photo_trace_app_walking: {
    logo: "",
    title: "TRACE Alpha on a walk",
    description: `Take a photo with the TRACE app on your smartphone while walking! Paste a link to the photo on Google Drive:`,
    link: "",
    responseForm: "link",
    cost: 2,
  },
  socilals_photo_trace_app_walking: {
    logo: "",
    title: "TRACE Alpha on a walk",
    description: `Post a photo on your social networks with the TRACE app on your smartphone while walking. Tag us: Twitter, Discord, Instagram, YouTube.`,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  video_trace_app_walking: {
    logo: "",
    title: "TRACE Alpha on a walk",
    description: `Make a video with the TRACE app on your smartphone while walking! Paste a link to the video on Google Drive:`,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  socilals_video_trace_app_walking: {
    logo: "",
    title: "TRACE Alpha on a walk",
    description: `Post a video on your social networks with the TRACE app on your smartphone while walking. Tag us: Twitter, Discord, Instagram, YouTube.`,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  photo_trace_app_car: {
    logo: "",
    title: "TRACE Alpha on a car",
    description: `Take a photo with the TRACE app on your smartphone in the car! Paste a link to the photo on Google Drive:`,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  socilals_photo_trace_app_car: {
    logo: "",
    title: "TRACE Alpha on a car",
    description: `Post a photo on your social networks with the TRACE app on your smartphone in the car! Tag us: Twitter, Discord, Instagram, YouTube.`,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  video_trace_app_car: {
    logo: "",
    title: "TRACE Alpha on a car",
    description: `Make a video with the TRACE app on your smartphone in the car! Paste a link to the video on Google Drive:`,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  socilals_video_trace_app_car: {
    logo: "",
    title: "TRACE Alpha on a car",
    description: `Post a video on your social networks with the TRACE app on your smartphone in the car. Tag us: Twitter, Discord, Instagram, YouTube.`,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  video_trace_app_plane: {
    logo: "",
    title: "TRACE Alpha on a plane ",
    description: `Make a video with the TRACE app on your smartphone on the plane! Paste a link to the video on Google Drive:`,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  socilals_video_trace_app_plane: {
    logo: "",
    title: "TRACE Alpha on a plane ",
    description: `Post a video to your social networks with the TRACE app on your smartphone on the plane. Tag us: Twitter, Discord, Instagram, YouTube.`,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  sphoto_trace_app_motorcycle: {
    logo: "",
    title: "TRACE Alpha on a motorcycle",
    description: `Take a photo with the TRACE app on a motorcycle/quad bike/jet ski/snowmobile/kart, etc.! Paste a link to the photo on Google Drive:
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  socilals_photo_trace_app_motorcycle: {
    logo: "",
    title: "TRACE Alpha on a motorcycle",
    description: `"Post a photo on your social networks with the TRACE app on a motorcycle / ATV / jet ski / snowmobile / map, etc.!
    Tag us: Twitter, Discord, Instagram, YouTube."
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  video_trace_app_motorcycle: {
    logo: "",
    title: "TRACE Alpha on a motorcycle",
    description: `Make a video with the TRACE app on a motorcycle/quad bike/jet ski/snowmobile/kart, etc.! Paste a link to the video on Google Drive:
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  socilals_video_trace_app_motorcycle: {
    logo: "",
    title: "TRACE Alpha on a motorcycle",
    description: `"Post to your social networks a video with the TRACE app on a motorcycle/quad bike/jet ski/snowmobile/kart, etc.!
    Tag us: Twitter, Discord, Instagram, YouTube."
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  photo_trace_app_sports: {
    logo: "",
    title: "TRACE Alpha Sports ",
    description: `Take a photo with the TRACE app on "sports" vehicles (bike, roller skates, scooter, skateboard, skis, skates, snowboard, etc.)! Paste a link to the photo on Google Drive:
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  socilals_photo_trace_app_sports: {
    logo: "",
    title: "TRACE Alpha Sports ",
    description: `"Post photos on your social networks with the TRACE app on ""sports"" vehicles (bicycle, roller skates, scooter, skateboard, skis, skates, snowboard, etc.)!
    Tag us: Twitter, Discord, Instagram, YouTube."
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  video_trace_app_sports: {
    logo: "",
    title: "TRACE Alpha Sports ",
    description: `Make a video with the TRACE app on "sports" vehicles (bike, roller skates, scooter, skateboard, skis, skates, snowboard, etc.)! Paste a link to the video on Google Drive:
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  socilals_video_trace_app_sports: {
    logo: "",
    title: "TRACE Alpha Sports ",
    description: `"Post on your social networks a video with the TRACE application on ""sports"" vehicles (bicycle, roller skates, scooter, skateboard, skis, skates, snowboard, etc.)!
    Tag us: Twitter, Discord, Instagram, YouTube."
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  twitter_video_guide_trace_app: {
    logo: "",
    title: "Guide on TRACE Alpha",
    description: `Make a video guide about the TRACE app and its features, post the video on Twitter and tag our account. Paste the link to the post here:
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  instagram_video_guide_trace_app: {
    logo: "",
    title: "Guide on TRACE Alpha",
    description: `Make a video guide about the TRACE app and its features, post the video on Instagram Reels and tag our account. Paste the link to the post here:
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  tiktok_video_guide_trace_app: {
    logo: "",
    title: "Guide on TRACE Alpha",
    description: `Make a video guide about the TRACE app and its features, post the video on TikTok and tag our account. Paste the link to the post here:
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  youtube_video_guide_trace_app: {
    logo: "",
    title: "Guide on TRACE Alpha",
    description: `Make a video guide about the TRACE app and its features, post the video to YouTube Shorts and tag our account. Paste the link to the post here:
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  twitter_post_trace_app_features: {
    logo: "",
    title: "TRACE Alpha-post",
    description: `Write a post about the TRACE app and its features, tweet it and tag our account. Paste the link to the post here:
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  instagram_post_trace_app_features: {
    logo: "",
    title: "TRACE Alpha-post",
    description: `Write a post about the TRACE app and its features, post it on Instagram and tag our account. Paste the link to the post here:
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  youtube_post_trace_app_features: {
    logo: "",
    title: "TRACE Alpha-post",
    description: `Write a post about the TRACE app and its features, publish it on YouTube and tag our account. Paste the link to the post here:
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  medium_post_trace_app_features: {
    logo: "",
    title: "TRACE Alpha-post",
    description: `Write a post about the TRACE app and its features, publish it on Medium and tag our account. Paste the link to the post here:
    `,
    link: "",
    responseForm: "link",
    cost: 3,
  },
  instagram_follow_eco: {
    logo: "instagram",
    title: "ECO TRACE Instagram",
    description: `Follow TRACE Instagram. Paste a screenshot link with the completed task on Google Drive:
    `,
    link: "",
    responseForm: "link",
    cost: 2,
  },
  tiktok_follow_eco: {
    logo: "tiktok",
    title: "ECO TRACE TikTok",
    description: `Follow TikTok. Paste a screenshot link with the completed task on Google Drive:
    `,
    link: "",
    responseForm: "link",
    cost: 2,
  },
  twitter_follow_eco: {
    logo: "twitter",
    title: "ECO TRACE Twitter",
    description: `Follow Twitter. Paste a screenshot link with the completed task on Google Drive:
    `,
    link: "",
    responseForm: "link",
    cost: 2,
  },
  telegram_follow_eco: {
    logo: "telegram",
    title: "ECO TRACE Telegram",
    description: `Follow Telegram. Paste a screenshot link with the completed task on Google Drive:
    `,
    link: "",
    responseForm: "link",
    cost: 2,
  },
  screensaver_phone_eco: {
    logo: "",
    title: "eco Wallpaper",
    description: `Put our branded screensaver on your phone and send us a screenshot.
    `,
    link: "https://drive.google.com/drive/folders/1qOwQZFZwu7_iLOiyadcNOR0JfMYynMGZ?usp=sharing",
    responseForm: "image",
    cost: 2,
  },
  photo_video_report_family_picnicTRACE: {
    logo: "",
    title: "#picnicTRACE",
    description: `Gather your friends/family for a walk or picnic with TRACE pictured on your smartphone! Post the photo and video report on social networks by tagging us Twitter, Instagram and put the hashtag #picnicTRACE
    `,
    link: "https://drive.google.com/drive/folders/1nPbPcwUr5q-lWpWFtu7PJYWAi7cmIPpq?usp=sharing",
    responseForm: "link",
    cost: 5,
  },
  screensaver_phone: {
    logo: "",
    title: "Wallpaper",
    description: `Put our branded screensaver on your phone and send us a screenshot
    `,
    link: "https://drive.google.com/drive/folders/1qOwQZFZwu7_iLOiyadcNOR0JfMYynMGZ?usp=sharing",
    responseForm: "image",
    cost: 2,
  },
  user_stickers_chat: {
    logo: "",
    title: "Stickers",
    description: `Use TRACE stickers to chat with friends! Attach screenshot here
    `,
    link: "https://t.me/addstickers/TRACEMetaverse",
    responseForm: "link",
    cost: 2,
  },
};
