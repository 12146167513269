import React, { useMemo } from "react";

import coinImage from "images/coin.png";

import s from "./ProgressBar.module.scss";

interface Props {
  maxBalance: number;
  dayPassed: number;
}

function ProgressBar({ maxBalance, dayPassed }: Props) {
  const daysPassed = useMemo(
    () => [
      dayPassed >= 1,
      dayPassed >= 2,
      dayPassed >= 3,
      dayPassed >= 4,
      dayPassed >= 5,
      dayPassed >= 6,
      dayPassed >= 7,
    ],
    [dayPassed],
  );

  return (
    <div className={s.progressBar}>
      <div className={s.segment}>
        <div className={`${s.fillWrapper} ${s.first}`}>
          <div className={`${s.fill} ${daysPassed[0] ? s.visible : ""}`} />
        </div>
        <div className={s.dayNumber}>Day 1</div>
      </div>
      <div className={s.segment}>
        <div className={s.fillWrapper}>
          <div className={`${s.fill} ${daysPassed[1] ? s.visible : ""}`} />
        </div>
        <div className={s.dayNumber}>Day 2</div>
      </div>
      <div className={s.segment}>
        <div className={s.fillWrapper}>
          <div className={`${s.fill} ${daysPassed[2] ? s.visible : ""}`} />
        </div>
        <div className={s.dayNumber}>Day 3</div>
        <div className={s.smallCoin}>
          <img src={coinImage} alt="coin" className="fill" />
        </div>
        <div className={`${s.bonusContainer} ${daysPassed[1] ? s.passed : ""}`}>
          <span className={s.plus}>+</span>
          <span className={s.value}>3 ACE</span>
        </div>
      </div>
      <div className={s.segment}>
        <div className={s.fillWrapper}>
          <div className={`${s.fill} ${daysPassed[3] ? s.visible : ""}`} />
        </div>
        <div className={s.dayNumber}>Day 4</div>
      </div>
      <div className={s.segment}>
        <div className={s.fillWrapper}>
          <div className={`${s.fill} ${daysPassed[4] ? s.visible : ""}`} />
        </div>
        <div className={s.dayNumber}>Day 5</div>
        <div className={s.bigCoin}>
          <img src={coinImage} alt="coin" className="fill" />
        </div>
        <div className={`${s.bonusContainer} ${daysPassed[4] ? s.passed : ""}`}>
          <span className={s.plus}>+</span>
          <span className={s.value}>5 ACE</span>
        </div>
      </div>
      <div className={s.segment}>
        <div className={s.fillWrapper}>
          <div className={`${s.fill} ${daysPassed[5] ? s.visible : ""}`} />
        </div>
        <div className={s.dayNumber}>Day 6</div>
      </div>
      <div className={s.segment}>
        <div className={s.fillWrapper}>
          <div className={`${s.fill} ${daysPassed[6] ? s.visible : ""}`} />
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
