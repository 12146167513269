import { useMutation } from "@tanstack/react-query";
import { sendCode } from "api/auth";
import { TokenResponse } from "types/user";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";
import { useTranslation } from "react-i18next";

interface Payload {
  email: string;
  code: string;
}

const useSendCode = () => {
  const { t } = useTranslation()
  const showSnackBar = useShowSnackBar();

  return useMutation<TokenResponse, Error, Payload>(
    ["send-code"],
    async ({ email, code }) => {
      if (code && email) {
        return sendCode({ email, code });
      }

      if (email == null) return Promise.reject(new Error("Invalid email"));
      if (code == null) return Promise.reject(new Error("Invalid code"));
      return Promise.reject(new Error("Invalid"));
    },
    {
      onSuccess: (data) => {
        return true;
      },
      onError: (error) => {
        showSnackBar({
          title: t("notification.commonError.title"),
          text: error.message,
          variant: "error",
        });
      },
    },
  );
};

export default useSendCode;
