import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import detectEthereumProvider from "@metamask/detect-provider";
import { SelectChangeEvent } from "@mui/material/Select";

import useChangeWallet from "hooks/query/useChangeWallet";
import useChangeUser from "hooks/query/useChangeUser";
import useGetUser from "hooks/query/useGetUser";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";

import Accordion from "components/Accordion";
import MinorSelect from "components/Select/MinorSelect";
import Button from "components/Button";

import s from "./ProfilePage.module.scss";

const selectItems = [
  {
    title: "English",
    value: "en",
  },
  {
    title: "Spanish",
    value: "es",
  },
  {
    title: "Portuguese",
    value: "pt",
  },
];

function ProfilePage() {
  const { i18n, t } = useTranslation();
  const showSnackBar = useShowSnackBar();

  const { data: user } = useGetUser();

  const [name, setName] = useState(user?.name || "");

  const [language, setLanguage] = useState(i18n.language);

  const handleChange = (event: SelectChangeEvent<any>) => {
    setLanguage(event.target.value);
  };

  const [metaMaskLoading, setMetaMaskLoading] = useState(false);
  const { mutate: changeWallet } = useChangeWallet();
  const { mutate: changeUser } = useChangeUser();

  const walletButtonHandler = useCallback(() => {
    const connectMask = async () => {
      setMetaMaskLoading(true);
      const provider = await detectEthereumProvider();
      if (provider) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        if (account) {
          changeWallet({ wallet_address: account });
        }
        showSnackBar({
          title: t("notification.walletConnect.title"),
          text: t("notification.walletConnect.text"),
        });
      } else {
        showSnackBar({
          title: t("notification.metaMaskError.title"),
          text: t("notification.metaMaskError.text"),
          variant: "error",
        });
      }
      setMetaMaskLoading(false);
    };

    connectMask();
  }, []);

  const nameButtonHandler = useCallback(() => {
    if (!name) return;
    const sendRequest = async () => {
      changeUser({ name });
    };
    sendRequest();
  }, [name]);

  const nameInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const languageButtonHandler = useCallback(() => {
    window.localStorage.setItem("lng", language);
    i18n.changeLanguage(language).then().catch();
    showSnackBar({
      title: t("notification.languageChanged.title"),
      text: t("notification.languageChanged.text"),
    });
  }, [language]);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div className={s.profilePage}>
      <Accordion
        expanded={String(t("profile.accordionTitle"))}
        title={String(t("profile.accordionTitle"))}
        noExpandedIcon
        backgroundColor="hsla(220, 18%, 51%, 1)"
        icon={<span className="icon-settings" />}
      >
        <div className={s.content}>
          <div className={s.row}>
            <div className={s.title}>{t("profile.name.title")}</div>
            <input
              type="text"
              className={s.input}
              value={name}
              onChange={nameInputHandler}
            />
            <div className={s.buttonWrapper}>
              <Button onClick={nameButtonHandler} disabled={name == null}>
                {t("profile.name.button")}
              </Button>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.title}>{t("profile.wallet.title")}</div>
            <input
              type="text"
              className={s.input}
              value={user?.wallet_address || ""}
              disabled
            />
            <div className={s.buttonWrapper}>
              <Button onClick={walletButtonHandler} disabled={metaMaskLoading}>
                {t("profile.wallet.button")}
              </Button>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.title}>{t("profile.language.title")}</div>
            <div className={s.selectWrapper}>
              <MinorSelect
                items={selectItems}
                value={language}
                onChange={handleChange}
              />
            </div>
            <div className={s.buttonWrapper}>
              <Button onClick={languageButtonHandler}>
                {t("profile.language.button")}
              </Button>
            </div>
          </div>
        </div>
      </Accordion>
    </div>
  );
}

export default ProfilePage;
