import React from "react";
import FAQ from "components/FAQ";
import s from "./FAQPage.module.scss";

function FAQPage() {
  return (
    <div className={s.FAQPage}>
      <div className={s.FAQSummery}>
        <span className={s.questionIcon}>?</span>
        <div className={s.title}>FAQ</div>
      </div>
      <div className={s.FAQWrapper}>
        <FAQ />
      </div>
    </div>
  );
}

export default FAQPage;
