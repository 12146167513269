/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Timer from "components/Timer";

import banner1 from "images/banner1.png";
import banner1es from "images/banner1es.png";
import banner1pt from "images/banner1pt.png";
import banner2 from "images/banner2.png";
import banner2es from "images/banner2es.png";
import banner2pt from "images/banner2pt.png";
import banner1Mobile from "images/banner1Mobile.png";
import banner1MobileEs from "images/banner1MobileEs.png";
import banner1MobilePt from "images/banner1MobilePt.png";
import banner2Mobile from "images/banner2Mobile.png";
import banner2MobileEs from "images/banner2MobileEs.png";
import banner2MobilePt from "images/banner2MobilePt.png";

import s from "./SideBanners.module.scss";

interface SideBannersProps {
  type?: "desktop" | "mobile";
  className?: string;
}

function SideBanners({ type = "desktop", className = "" }: SideBannersProps) {
  const { i18n } = useTranslation();
  const getBanner1 = useMemo(() => {
    switch (i18n.language) {
      case "en":
        return banner1;
      case "es":
        return banner1es;
      case "pt":
        return banner1pt;
      default:
        return banner1;
    }
  }, [i18n.language]);
  const getBanner1Mobile = useMemo(() => {
    switch (i18n.language) {
      case "en":
        return banner1Mobile;
      case "es":
        return banner1MobileEs;
      case "pt":
        return banner1MobilePt;
      default:
        return banner1Mobile;
    }
  }, [i18n.language]);
  const getBanner2 = useMemo(() => {
    switch (i18n.language) {
      case "en":
        return banner2;
      case "es":
        return banner2es;
      case "pt":
        return banner2pt;
      default:
        return banner2;
    }
  }, [i18n.language]);
  const getBanner2Mobile = useMemo(() => {
    switch (i18n.language) {
      case "en":
        return banner2Mobile;
      case "es":
        return banner2MobileEs;
      case "pt":
        return banner2MobilePt;
      default:
        return banner2Mobile;
    }
  }, [i18n.language]);

  return (
    <div className={`${s.sideBanners} ${className}`}>
      <div className={s.timerWrapper}>
        <Timer />
      </div>
      <div className={s.banner}>
        <img
          src={type === "desktop" ? getBanner1 : getBanner1Mobile}
          alt=""
          className={s.image}
        />
        {/* <a
          href="
          https://twitter.com/trace_top/status/1608067184055488512"
          className="link"
          target="_blank"
          rel="noreferrer"
        /> */}
      </div>
      <div className={s.banner}>
        <img
          src={type === "desktop" ? getBanner2 : getBanner2Mobile}
          alt=""
          className={s.image}
        />
        <a
          href="https://linktr.ee/trace_meta"
          className="link"
          target="_blank"
          rel="noreferrer"
        />
      </div>
    </div>
  );
}

export default SideBanners;
