import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useGetUser from "hooks/query/useGetUser";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";

import SideNavigation from "components/SideNavigation";
import SideBanners from "components/SideBanners";
import MetaMask from "components/MetaMask";

import avatarImg from "images/avatar.png";

import s from "./DrawerContent.module.scss";

interface Props {
  setReferralModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleDrawer: (
    toggle: boolean,
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  logOut: () => void;
}

function DrawerContent({ toggleDrawer, logOut, setReferralModalOpen }: Props) {
  const { t } = useTranslation();
  const { data: user } = useGetUser();
  const showSnackBar = useShowSnackBar();

  const sideNavigationElements = useMemo(
    () => [
      // {
      //   link: "/",
      //   title: t("navigation.missions"),
      //   icon: <span className="icon-mission" />,
      // },
      {
        link: "/referral",
        title: t("navigation.referral"),
        icon: <span className="icon-people" />,
        isBlocked: !user?.is_ref_enabled,
        onClick: !user?.is_ref_enabled
          ? () => setReferralModalOpen(true)
          : null,
      },
      {
        title: "promo codes",
        icon: <span className="icon-present" />,
        isBlocked: true,
        onClick: () =>
          showSnackBar({
            title: t("notification.soon.title"),
            text: t("notification.soon.text"),
          }),
      },
      // {
      //   title: "inventory",
      //   icon: <span className="icon-inventory" />,
      // },
      {
        link: "/faq",
        title: "FAQ",
        icon: (
          <span className={s.questionIcon} data-question="true">
            ?
          </span>
        ),
        grayIcon: true,
      },
      {
        link: "/profile",
        title: t("navigation.settings"),
        icon: <span className="icon-settings" />,
        grayIcon: true,
      },
      {
        onClick: logOut,
        title: t("navigation.logOut"),
        icon: <span className="icon-logOut" />,
        grayIcon: true,
      },
    ],
    [logOut, t],
  );

  return (
    <div className={s.drawerContent}>
      <div className={s.drawerSpaceTop} />
      <div className={s.drawerWrapper}>
        <div className={s.user}>
          <div className={s.avatarWrapper}>
            <img src={avatarImg} alt="" className={s.avatar} />
          </div>
          <div className={s.userNameWrapper}>
            {user?.name && <div className={s.name}>{user?.name}</div>}
            <div className={s.email}>{user?.email}</div>
          </div>
        </div>
        <div className={s.metaMaskWrapper}>
          <MetaMask />
        </div>
        <button
          type="button"
          className={s.drawerNavigationWrapper}
          onClick={toggleDrawer(false)}
        >
          <SideNavigation
            elements={sideNavigationElements}
            className={s.drawerNavigation}
          />
        </button>
        <button
          type="button"
          className={s.drawerBannersWrapper}
          onClick={toggleDrawer(false)}
        >
          <SideBanners type="mobile" className={s.drawerBanners} />
        </button>
      </div>
      <div className={s.drawerSpaceBottom} />
    </div>
  );
}

export default DrawerContent;
