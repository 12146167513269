import { ApiError } from "types/error";

const endpoint = process.env.REACT_APP_API_ENDPOINT || "";

export const changeWallet = async (
  token: string,
  data: { wallet_address: string },
): Promise<void> => {
  const body = JSON.stringify(data);

  const response = await fetch(`${endpoint}profile`, {
    method: "PUT",
    body,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    let errorData: ApiError;
    try {
      errorData = await response.json();
    } catch (error) {
      throw new Error("API error");
    }
    throw new Error(errorData?.message);
  }
};

export const changeName = async (
  token: string,
  data: { name: string },
): Promise<void> => {
  const body = JSON.stringify(data);

  const response = await fetch(`${endpoint}profile`, {
    method: "PUT",
    body,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    let errorData: ApiError;
    try {
      errorData = await response.json();
    } catch (error) {
      throw new Error("API error");
    }
    throw new Error(errorData?.message);
  }
};

interface ExtraData {
  role: string;
  private_info?: string;
}

export const changeRoleAndPrivate = async (
  token: string,
  { role, private_info }: ExtraData,
): Promise<void> => {
  const data: ExtraData = { role };
  if (private_info) {
    data.private_info = private_info;
  }
  const body = JSON.stringify(data);

  const response = await fetch(`${endpoint}profile`, {
    method: "PUT",
    body,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    let errorData: ApiError;
    try {
      errorData = await response.json();
    } catch (error) {
      throw new Error("API error");
    }
    throw new Error(errorData?.message);
  }
};

export const openReferral = async (
  token: string,
  data: { description: string },
): Promise<void> => {
  const body = JSON.stringify(data);

  const response = await fetch(`${endpoint}profile/request-referral`, {
    method: "POST",
    body,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    let errorData: ApiError;
    try {
      errorData = await response.json();
    } catch (error) {
      throw new Error("API error");
    }
    throw new Error(errorData?.message);
  }
};
