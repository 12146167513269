import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import getLanguage from "utils/getLanguage";

function LangInitializer() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const language = getLanguage();
    i18n.changeLanguage(language).then().catch();
  }, []);

  return null;
}

export default LangInitializer;
