import { Task } from "types/task";
import { ApiError } from "types/error";

const endpoint = process.env.REACT_APP_API_ENDPOINT || "";

export const getTasks = async (token: string): Promise<Task[]> => {
  const response = await fetch(`${endpoint}tasks`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    let errorData: ApiError;
    try {
      errorData = await response.json();
    } catch (error) {
      throw new Error("API error");
    }
    throw new Error(errorData?.message);
  }

  return response.json();
};

export const checkTask = async (
  token: string,
  {
    codename,
    result,
    file,
  }: { codename: string; result?: string; file?: File },
): Promise<void> => {
  const formData = new FormData();

  if (result) {
    formData.append("result", result);
  }
  if (file) {
    formData.append("file", file);
  }

  const response = await fetch(`${endpoint}tasks/check/${codename}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });

  if (!response.ok) {
    let errorData: ApiError;
    try {
      errorData = await response.json();
    } catch (error) {
      throw new Error("API error");
    }
    throw new Error(errorData?.message);
  }
};
