import { createContext } from "react";

export interface AuthContextInterface {
  isAuthInitialized: boolean;
  setIsAuthInitialized: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface,
);

export default AuthContext;
