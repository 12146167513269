import { useQuery } from "@tanstack/react-query";
import { getTasks } from "api/missions";
import { Task } from "types/task";
import getFirebaseIdToken from "utils/getFirebaseIdToken";

const useGetTasks = () => {
  return useQuery<Task[] | null, Error>(["tasks"], async () => {
    const token = await getFirebaseIdToken();
    if (token) {
      return getTasks(token);
    }
    return Promise.resolve(null);
  });
};

export default useGetTasks;
