import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Codename, TaskCategory, MissionData } from "types/task";

import ButtonLink from "components/ButtonLink";
import Reward from "components/Reward";
import Accordion from "components/Accordion";

import { missionsData } from "./missionsData";

import s from "./DailyMission.module.scss";
import Mission from "./Mission";

interface Props {
  cost: number;
  codename: Codename;
  completed: boolean;
  category: TaskCategory;
  expanded: string | false;
  handleChange: (
    panel: string,
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

function DailyMission({
  cost,
  codename,
  category,
  completed,
  expanded,
  handleChange,
}: Props) {
  const { t } = useTranslation();

  const missionData = missionsData?.[codename] || {
    logo: "",
    title: "unknown",
    description: "unknown",
    link: "",
  };

  const icon = useMemo(() => {
    switch (missionData.logo) {
      case "telegram":
        return <span className="icon-telegram" />;
      case "medium":
        return <span className="icon-medium" />;
      case "twitter":
        return <span className="icon-twitter" />;
      case "lindkedln":
        // return <span className="icon-lindkedln" />;
        return null;
      case "instagram":
        return <span className="icon-instagram" />;
      case "tiktok":
        // return <span className="icon-tiktok" />;
        return null;
      case "youTube":
        return <span className="icon-youtube" />;
      case "discord":
        return <span className="icon-discord" />;
      default:
        return null;
    }
  }, [missionData.logo]);

  const accordionColor = useMemo(() => {
    switch (category) {
      case "link":
        return "hsla(219, 79%, 62%,";
      case "image":
        return "hsla(259, 79%, 62%,";
      case "short_text":
        return "hsla(342, 66%, 55%,";
      case "long_text":
        return "hsla(14, 81%, 60%,";

      default:
        return "hsla(219, 79%, 62%,";
    }
  }, [category]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      backgroundColor={`${accordionColor} 1)`}
      backgroundColorShadow={`${accordionColor} 0.5)`}
      title={missionData.title}
      uniqueKey={codename}
      icon={icon}
      completed={completed}
      cost={cost}
      style={{
        order: completed ? 2 : 1,
      }}
    >
      <div className={s.missionContent}>
        <Mission
          variant={completed ? "completed" : category}
          cost={cost}
          missionData={missionData}
          codename={codename}
        />
      </div>
    </Accordion>
  );
}

export default DailyMission;
