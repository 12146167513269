import React from "react";

import { InventoryCodeName } from "types/inventory";

import containerImage from "images/container.png";
import coinImage from "images/coin2.png";
import boxImage from "images/box.png";
import merchImage from "images/merch.png";
import spinImage from "images/spin.png";
import phoneImage from "images/phone.png";

import s from "./InventoryItem.module.scss";

interface Props {
  variant: InventoryCodeName;
  noShadow?: boolean;
  uuid?: string;
}

function InventoryItem({
  variant,
  uuid = "000000000",
  noShadow = false,
}: Props) {
  switch (variant) {
    case "nft_pass":
      return (
        <div
          className={`${s.inventoryItem} ${s.nft_pass} ${
            noShadow ? s.noShadow : ""
          }`}
        >
          <div className={s.title}>NFT CONTAINER</div>
          <div className={s.imageWrapper}>
            <img src={containerImage} alt="inventory item" className="fill" />
          </div>
          <div className={s.code}>{uuid}</div>
        </div>
      );
    case "ace_5":
      return (
        <div
          className={`${s.inventoryItem} ${s.ace_5} ${
            noShadow ? s.noShadow : ""
          }`}
        >
          <div className={s.title}>
            <span className={s.plus}>+</span> 5 ace
          </div>
          <div className={s.imageWrapper}>
            <img src={coinImage} alt="inventory item" className="fill" />
          </div>
          <div className={s.code}>{uuid}</div>
        </div>
      );
    case "ace_10":
      return (
        <div
          className={`${s.inventoryItem} ${s.ace_10} ${
            noShadow ? s.noShadow : ""
          }`}
        >
          <div className={s.title}>
            <span className={s.plus}>+</span> 10 ace
          </div>
          <div className={s.imageWrapper}>
            <img src={coinImage} alt="inventory item" className="fill" />
          </div>
          <div className={s.code}>{uuid}</div>
        </div>
      );
    case "ace_15":
      return (
        <div
          className={`${s.inventoryItem} ${s.ace_15} ${
            noShadow ? s.noShadow : ""
          }`}
        >
          <div className={s.title}>
            <span className={s.plus}>+</span> 15 ace
          </div>
          <div className={s.imageWrapper}>
            <img src={coinImage} alt="inventory item" className="fill" />
          </div>
          <div className={s.code}>{uuid}</div>
        </div>
      );
    case "nft_car_tier_1":
      return (
        <div
          className={`${s.inventoryItem} ${s.nft_car_tier_1} ${
            noShadow ? s.noShadow : ""
          }`}
        >
          <div className={s.title}>merch trace</div>
          <div className={s.imageWrapper}>
            <img src={merchImage} alt="inventory item" className="fill" />
          </div>
          <div className={s.code}>{uuid}</div>
        </div>
      );
    case "nft_box":
      return (
        <div
          className={`${s.inventoryItem} ${s.nft_box} ${
            noShadow ? s.noShadow : ""
          }`}
        >
          <div className={s.title}>nft box</div>
          <div className={s.imageWrapper}>
            <img src={boxImage} alt="inventory item" className="fill" />
          </div>
          <div className={s.code}>{uuid}</div>
        </div>
      );
    case "alpha_invite":
      return (
        <div
          className={`${s.inventoryItem} ${s.alpha_invite} ${
            noShadow ? s.noShadow : ""
          }`}
        >
          <div className={s.titleWrapper}>
            <div className={s.title}>invite to alpha</div>
            <div className={s.multiply}>x1</div>
          </div>
          <div className={s.imageWrapper}>
            <img src={phoneImage} alt="inventory item" className="fill" />
          </div>
          <div className={s.code}>{uuid}</div>
        </div>
      );
    case "refferal_invite":
      return (
        <div
          className={`${s.inventoryItem} ${s.refferal_invite} ${
            noShadow ? s.noShadow : ""
          }`}
        >
          <div className={s.titleWrapper}>
            <div className={s.title}>invite to refferal</div>
            <div className={s.multiply}>x1</div>
          </div>
          <div className={s.imageWrapper}>
            <img src={phoneImage} alt="inventory item" className="fill" />
          </div>
          <div className={s.code}>{uuid}</div>
        </div>
      );
    case "lootbox_reroll":
      return (
        <div
          className={`${s.inventoryItem} ${s.lootbox_reroll} ${
            noShadow ? s.noShadow : ""
          }`}
        >
          <div className={s.title}>1 spin again</div>
          <div className={s.imageWrapper}>
            <img src={spinImage} alt="inventory item" className="fill" />
          </div>
          <div className={s.code}>{uuid}</div>
        </div>
      );
    default:
      return (
        <div className={s.inventoryItem}>
          <div className={s.title}>Unknown</div>
          <div className={s.code}>{uuid}</div>
        </div>
      );
  }
}

export default InventoryItem;
