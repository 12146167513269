import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { signOut } from "firebase/auth";
import { auth } from "utils/firebase";
import Menu from "@mui/material/Menu";

import useGetUser from "hooks/query/useGetUser";
import useShowSnackBar from "hooks/snackBar/useShowSnackBar";

import MetaMask from "components/MetaMask";
import LanguageSelect from "components/Select/LanguageSelect";

import logoImg from "images/logo.svg";
import avatarImg from "images/avatar.png";

import s from "./Header.module.scss";

const selectItems = [
  {
    title: "EN",
    value: "en",
  },
  {
    title: "ES",
    value: "es",
  },
  {
    title: "PT",
    value: "pt",
  },
];

function Header() {
  const queryClient = useQueryClient();
  const { i18n, t } = useTranslation();
  const showSnackBar = useShowSnackBar();
  const { data: user } = useGetUser();
  const [language, setLanguage] = useState(i18n.language);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logOut = async () => {
    await signOut(auth);
    queryClient.invalidateQueries({ queryKey: ["user"] });
  };

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setLanguage(value);
    window.localStorage.setItem("lng", value);
    i18n.changeLanguage(value).then().catch();
    showSnackBar({
      title: t("notification.languageChanged.title"),
      text: t("notification.languageChanged.text"),
    });
  };

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div className={s.header}>
      <div className={s.fixedHeader}>
        <div className={s.container}>
          <div className={s.leftPart}>
            <div className={s.logoWrapper}>
              <img src={logoImg} alt="" className={s.logo} />
              <NavLink to="/" className="link" />
            </div>
            <div className={s.changeLanguage}>
              <LanguageSelect
                items={selectItems}
                value={language}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={s.rightPart}>
            <div className={s.metaMaskWrapper}>
              <MetaMask />
            </div>
            <div className={s.balance}>
              <div className={s.balanceIcon}>
                <span className="icon-balanceWrapped" />
              </div>
              <span className={s.value}>{user?.balance}</span>
            </div>
            <div className={s.userWrapper}>
              <button
                type="button"
                className={`${s.user} ${open ? s.open : ""}`}
                onClick={handleClick}
              >
                <div className={s.name}>{user?.name || user?.email}</div>
                <div className={s.avatarWrapper}>
                  <img src={avatarImg} alt="" className={s.avatar} />
                </div>
                <div className={s.triangle}>
                  <span className="icon-triangle" />
                </div>
              </button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={s.menu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
                MenuListProps={{
                  style: {
                    width: "289px",
                    padding: "0",
                  },
                }}
              >
                <button
                  type="button"
                  onClick={handleClose}
                  className={s.menuItem}
                >
                  <div className={s.iconWrapper}>
                    <span className="icon-settings" />
                  </div>
                  <span className={s.title}>{t("navigation.settings")}</span>
                  <NavLink to="/profile" className="link" />
                </button>
                <button type="button" onClick={logOut} className={s.menuItem}>
                  <div className={s.iconWrapper}>
                    <span className="icon-logOut" />
                  </div>
                  <span className={s.title}>{t("navigation.logOut")}</span>
                </button>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
