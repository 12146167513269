import React, { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { useQueryClient } from "@tanstack/react-query";

import s from "./Timer.module.scss";

function Timer() {
  const queryClient = useQueryClient();
  const now = useMemo(() => DateTime.utc().toObject(), []);
  const [hours, setHours] = useState(24 - Number(now.hour));
  const [minutes, setMinutes] = useState(60 - Number(now.minute));
  const [seconds, setSeconds] = useState(60 - Number(now.second));

  useEffect(() => {
    const interval = setInterval(() => {
      const nowInInterval = DateTime.utc().toObject();
      setHours(24 - Number(nowInInterval.hour));
      setMinutes(60 - Number(nowInInterval.minute));
      setSeconds(60 - Number(nowInInterval.second));

      if (
        Number(nowInInterval.hour) === 0 &&
        Number(nowInInterval.minute) === 1 &&
        Number(nowInInterval.second) === 0
      ) {
        queryClient.invalidateQueries({ queryKey: ["tasks"] });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={s.timer}>
      <div className={s.title}>daily tasks timer</div>
      <div className={s.bottomPart}>
        <div className={s.container}>
          <div className={s.innerContainer}>
            <div className={s.time}>{hours}</div>
            <div className={s.separator}>:</div>
            <div className={s.time}>{minutes}</div>
            <div className={s.separator}>:</div>
            <div className={s.time}>{seconds}</div>
          </div>
          <div className={s.background} />
          <div className={s.background2} />
        </div>

        <div className={s.labels}>
          <span>Hours</span>
          <span>Minutes</span>
          <span>Seconds</span>
        </div>
      </div>
    </div>
  );
}

export default Timer;
