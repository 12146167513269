import React, { useMemo, useState } from "react";

import Skeleton from "@mui/material/Skeleton";

import useGetInventory from "hooks/query/useGetInventory";

import Switch from "components/Switch";
import InventoryItem from "components/InventoryItem";

import s from "./InventoryPage.module.scss";

const tabs = ["MY NFT", "gift NFT"];

function InventoryPage() {
  const [tab, setTab] = useState(0);

  const { data: inventory, isLoading } = useGetInventory();

  const emptyItems = useMemo(() => {
    if (!inventory) {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8];
    }
    if (inventory.length < 9) {
      const howManyItems = 9 - inventory.length;

      const array = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < howManyItems; i++) {
        array.push(i);
      }
      return array;
    }
    return [];
  }, [inventory]);

  return (
    <div className={s.inventoryPage}>
      <div className={s.title}>your inventory</div>
      <div className={s.controlPanel}>
        <div className={s.switcher}>
          <Switch currentIndex={tab} setCurrentIndex={setTab} elements={tabs} />
        </div>
        <div className={s.claim}>
          <div className={s.iconWrapper}>
            <span className="icon-lock" />
          </div>
          Staking
        </div>
      </div>
      <div className={s.inventoryGrid}>
        {isLoading &&
          emptyItems.map((elem) => (
            <Skeleton
              width="100%"
              variant="rectangular"
              style={{ borderRadius: "25px" }}
              // animation={false}
              key={elem}
            >
              <InventoryItem variant="nft_pass" />
            </Skeleton>
          ))}
        {!isLoading &&
          inventory?.map((item) => (
            <InventoryItem
              variant={item.codename}
              uuid={item.uuid}
              key={item.id}
            />
          ))}
        {!isLoading &&
          emptyItems?.map((item) => (
            <Skeleton
              width="100%"
              variant="rectangular"
              style={{
                borderRadius: "25px",
                backgroundColor: "rgba(107, 122, 153, 0.1)",
              }}
              animation={false}
              key={item}
            >
              <InventoryItem variant="nft_pass" />
            </Skeleton>
          ))}
        {/* {!isLoading &&
          inventory &&
          inventory?.length < 1 &&
          [0, 1, 2, 3, 4, 5, 6, 7, 8].map((elem) => (
            <Skeleton
              width="100%"
              variant="rectangular"
              style={{
                borderRadius: "25px",
                backgroundColor: "rgba(107, 122, 153, 0.1)",
              }}
              animation={false}
              key={elem}
            >
              <InventoryItem variant="nft_pass" />
            </Skeleton>
          ))} */}
      </div>
    </div>
  );
}

export default InventoryPage;
