/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { NavLink } from "react-router-dom";

import s from "./ButtonLink.module.scss";

interface ButtonLinkProps {
  children: React.ReactNode | string;
  link: string;
  isRoutingButton?: boolean;
  className?: string;
}

function ButtonLink({
  children = "",
  link,
  isRoutingButton = false,
  className = "",
}: ButtonLinkProps) {
  return isRoutingButton ? (
    <NavLink to={link} className={`${s.button} ${className}`}>
      {children}
    </NavLink>
  ) : (
    <a
      href={link}
      className={`${s.button} ${className}`}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </a>
  );
}

export default ButtonLink;
