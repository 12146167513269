/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useMemo, useState } from "react";
import getFirebaseIdToken from "utils/getFirebaseIdToken";
import useCloseModal from "hooks/modal/useCloseModal";

import s from "./SocialModal.module.scss";
import ModalContainer from "../ModalContainer";

interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  social: string;
}

function SocialModal({ open, setOpen, social }: ModalProps) {
  const [token, setToken] = useState<string | undefined>("");
  const closeHandler = () => setOpen(false);
  const { isClosing, onClose } = useCloseModal(closeHandler);

  const socialClassName = useMemo(() => {
    switch (social) {
      case "twitter":
        return s.twitter;
      case "discord":
        return s.discord;
      case "telegram":
        return s.telegram;

      default:
        return "";
    }
  }, [social]);

  useEffect(() => {
    const getToken = async () => {
      const value = await getFirebaseIdToken();
      setToken(value);
    };
    getToken();
  }, [open]);

  return (
    <ModalContainer open={open} onClose={onClose} isClosing={isClosing}>
      <div className={s.modalWindowWrapper}>
        <div className={`${s.modalWindow} ${socialClassName}`}>
          <div className={s.summery}>
            <div className={s.title}>Connect {social} account</div>
            <button type="button" className={s.close} onClick={closeHandler}>
              <span className="icon-close" />
            </button>
          </div>
          <div className={s.content}>
            <div className={s.text}>
              To check tasks and calculate Ace, you must connect your {social}{" "}
              account
            </div>
            <a
              href={`https://trace-core.flamma.app/profile/connect/${social}?token=${token}`}
              className={s.connect}
              target="_blank"
              rel="noreferrer"
              onClick={closeHandler}
            >
              <span className={`${s.icon} icon-${social}`} />
              Connect
            </a>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}

export default SocialModal;
