import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import getLanguage from "utils/getLanguage";
import enTranslation from "./en/translation.json";
import esTranslation from "./es/translation.json";
import ptTranslation from "./pt/translation.json";

export const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
  pt: {
    translation: ptTranslation,
  },
} as const;

const lng = getLanguage();

i18n.use(initReactI18next).init({
  lng,
  interpolation: { escapeValue: false },
  resources,
});

export default i18n;
